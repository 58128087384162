import renderChartPairOfTypes from "./chartPairOfTypes";
import renderChartPersonalityTypes from "./chartPersonalityType";
import renderGlobalGenderPersonalityTypes from "./chartGlobalGenderPersonalityTypes";
import renderPersonalityTypeAndJob from "./chartPersonalityTypeAndJob";
import renderTemperament from "./chartTemperament";

async function rendersCharts(userTypePersonality, userType, user) {
  await renderChartPersonalityTypes(
    "bar",
    ["EKSTRAWERSJA", "POZNANIE", "MYŚLENIE", "OSĄDZANIE"],
    [
      userTypePersonality.resultExtravert,
      userTypePersonality.resultSensig,
      userTypePersonality.resultThinking,
      userTypePersonality.resultJudging,
    ],
    [
      "rgb(47, 132, 239)",
      "rgb(182, 182, 182)",
      "rgb(73, 170, 255)",
      "rgb(38, 112, 178)",
    ],
    "start",
    "left",
    "left",
    true,
    `Extravert-Sensing-Thinking-Judging ${userType}`
  );
  await renderChartPersonalityTypes(
    "bar",
    ["INTROWERSJA", "INTUICJA", "ODCZUWANIE", "OBSERWACJA"],
    [
      userTypePersonality.resultIntrovert,
      userTypePersonality.resultIntuition,
      userTypePersonality.resultFeeling,
      userTypePersonality.resultPerceiving,
    ],
    [
      "rgb(255, 145, 0)",
      "rgb(255, 255, 102)",
      "rgb(17, 146, 13)",
      "rgb(172, 0, 0)",
    ],
    "end",
    "right",
    "right",
    false,
    `Introvert-Intuition-Feeling-Perceiving ${userType}`
  );
  await renderChartPairOfTypes(
    "bar",
    ["EKSTRAWERSJA", "INTROWERSJA"],
    [userTypePersonality.resultExtravert, userTypePersonality.resultIntrovert],
    ["rgb(47, 132, 239)", "rgb(255, 145, 0)"],
    `ExtravertIntrover ${userType}`
  );
  await renderChartPairOfTypes(
    "bar",
    ["POZNANIE", "INTUICJA"],
    [userTypePersonality.resultSensig, userTypePersonality.resultIntuition],
    ["rgb(182, 182, 182)", "rgb(255, 255, 102)"],
    `SensingIntuition ${userType}`
  );
  await renderChartPairOfTypes(
    "bar",
    ["MYŚLENIE", "ODCZUWANIE"],
    [userTypePersonality.resultThinking, userTypePersonality.resultFeeling],
    ["rgb(73, 170, 255)", "rgb(17, 146, 13)"],
    `ThinkingFeeling ${userType}`
  );
  await renderChartPairOfTypes(
    "bar",
    ["OSĄDZANIE", "OBSERWACJA"],
    [userTypePersonality.resultJudging, userTypePersonality.resultPerceiving],
    ["rgb(38, 112, 178)", "rgb(172, 0, 0)"],
    `JudgingPerceiving ${userType}`
  );
  await renderGlobalGenderPersonalityTypes(
    userType,
    user,
    `chartGlobalGenderTypesPersonality ${userType}`
  );
  await renderPersonalityTypeAndJob("PersonalityTypeJob", userType);
  await renderTemperament(userType, `Temperament ${userType}`);
}

export default rendersCharts;
