import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import createElementCanvas from "./createElementCanvas.js";

const renderChartPersonalityTypes = (
  type,
  labels,
  data,
  backgroundColor,
  anchorPosition,
  alignPosition,
  positionY,
  reverseY,
  id
) => {
  return new Promise((resolve, reject) => {
    const ctx = createElementCanvas(id, "chart-container");
    new Chart(ctx, {
      type: type,
      plugins: [ChartDataLabels],
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColor,
            borderWidth: 1,
          },
        ],
      },
      options: {
        devicePixelRatio: 1.5,
        aspectRatio: 0,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            formatter: (value, ctx) => {
              const maxAnswearPoints = 70;
              let percentage =
                ((value * 100) / maxAnswearPoints).toFixed(1) + "%";
              return percentage;
            },
            anchor: anchorPosition,
            align: alignPosition,
            labels: {
              value: {
                color: "black",
              },
            },
          },
        },
        indexAxis: "y",
        scales: {
          x: {
            min: 0,
            max: 70,
            reverse: reverseY,
            beginAtZero: true,
            title: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
          y: {
            position: positionY,
            ticks: {
              padding: 20,
            },
          },
        },
        animation: {
          duration: 0,
          onComplete: function () {
            resolve();
          },
        },
      },
    });
  });
};

export default renderChartPersonalityTypes;
