export const allTemperamentTypes = [
  {
    personalityTypes: "TWÓRCY SANGWINICY",
    types: [
      {
        type: "ESTP",
        strengths: [
          "- Charyzmatyczne i bezpośrednie podejście do ludzi, co pomaga w nawiązywaniu relacji z potencjalnymi klientami",
          "- Umiejętność szybkiego podejmowania decyzji i elastyczność, co pozwala na szybką reakcję na zmieniające się potrzeby klienta",
          "- Zdolność do pracy pod presją i w stresie, co pozwala na sprawne działanie w trudnych sytuacjach",
          "- Zdolność do energicznego i entuzjastycznego przedstawiania propozycji, co przyciąga uwagę potencjalnych klientów",
        ],
        weaknesses: [
          "- Tendencja do podejmowania ryzyka bez wcześniejszej analizy sytuacji, co może prowadzić do błędnych decyzji",
          "- Tendencja do impulsywności i braku długoterminowego planowania, co może negatywnie wpłynąć na budowanie trwałych relacji z klientami",
          "- Tendencja do ignorowania szczegółów i zbytnie skupianie się na działaniu, co może powodować niedociągnięcia i brak pełnego zrozumienia sytuacji",
          "- Tendencja do uogólniania i ignorowania indywidualnych potrzeb i wymagań klienta, co może powodować utratę zaufania",
        ],
        summary: [
          "- Utrzymuj bezpośrednie i charyzmatyczne podejście, ale staraj się dokładniej analizować sytuację przed podjęciem ryzyka",
          "- Uważaj na impulsywność i staraj się budować trwałe relacje z klientami poprzez długoterminowe planowanie i strategię działania",
          "- Uważaj na szczegóły i staraj się pełniej zrozumieć sytuację, aby uniknąć niedociągnięć i zapewnić najlepszą obsługę klienta",
          "- Staraj się indywidualnie dopasowywać propozycje do potrzeb",
        ],
      },
      {
        type: "ESTJ",
        strengths: [
          "- Duża praktyczność ukierunkowanie na wynik",
          "- Naturalne zdolności organizacyjne",
          "- Łatwość podejmowania obiektywnych decyzji",
          "- Skuteczność w realizacji zobowiązań, stanowczość w razie potrzeby",
          "- Dostrzeganie tego co nielogiczne, niekonsekwentne, niepraktyczne albo nieskuteczne",
        ],
        weaknesses: [
          "- Niecierpliwość wobec tych, którzy nie przestrzegają procedur albo nie zwracają uwagi na ważne szczegóły. Brak cierpliwości wobec nieefektywności",
          "- Tendencje do pomijania ludzi w podążaniu za celami",
          "- Brak zainteresowania możliwościami, które nie istnieją w chwili obecnej",
          "- Niechęć do słuchania odmiennych poglądów i punktów widzenia",
          "- Szorstkość i brak okrzesania",
        ],
        summary: [
          "- Postaraj się widzieć potencjały w osobach, które preferują widzieć bardziej ogólny niż szczegółowy obraz. Niektórzy potrzebują więcej czasu niż Ty aby coś zrozumieć, nauczyć się i wyciągnąć trafne wnioski",
          "- Staraj się dostrzegać talenty i wysiłki innych oraz wyrażać pochwały i komlementy",
          "- Przed podjęciem decyzji rozważ przez chwilę jej wpływ na innych oraz, zastanów się nad alternatywami",
          "- Postaraj się być bardziej  bezstronnym słuchając czyichś opinii czy przekonań, inaczej ludzie będą odbierać Cię jako osobę nieelastyczną i sztywną. Używaj cześciej pytania typu „Co by było gdyby” jeżeli chcesz doprowadzić kogoś do wniosków na których Ci zależy. Niech jednak intencją będzie ciekawość a nie widoczna chęć przekonania kogoś",
          "- Bycie naturalnym krytycznym nie wyklucza bycia taktownym. Weź to pod uwagę. Odrobina dyplomacji będzie Cię w tym wspierać.",
        ],
      },
      {
        type: "ESTJ",
        strengths: [
          "- Dobra współpraca. Tworzenie przyjaznych i harmonijnych relacji",
          "- Utrzymanie osobistego podejścia do spraw niezależnie od zawodu",
          "- Pracowitość, wydajność, sumienność i lojalność",
          "- Dobre przystosowanie do czynności rutynowych. Przestrzeganie reguł i przepisów",
          "- Dobre zorganizowanie, zdolność zapamiętywania i wykorzystania faktów",
        ],
        weaknesses: [
          "- Wrażliwość na krytykę. Stresowanie się sytuacjami wypełnionymi napięciem",
          "- Zniechęcanie się przy braku pochwały i uznania",
          "- Zbyt szybkie podejmowanie decyzji, bez rozważenia innych opcji",
          "- Niechęć do poszukiwania nowych sposobów postępowania. Upór i brak elastyczności",
          "- Niepokój przy dłuższej pracy w samotności. Potrzeba towarzystwa",
        ],
        summary: [
          "- Przyjmuj konstruktywną krytykę w duchu w jakim jest zamierzona i staraj się nie traktować jej zbyt osobiście",
          "- Staraj się dostrzegać różnicę pomiędzy pochwałą za wynik a pochwałą za drogę do wyniku. Preferujesz pochwałę jak zakończysz zadanie czy potrzebujesz pochwał aby kontynuować?",
          "- Staraj się zdystansować wobec sytuacji aby spojrzeć na nią bardziej obiektywnie.",
          "- Bądź elastyczny i otwarty na różnorodne perspektywy, aby wykorzystać potencjał innych i osiągnąć innowacyjne rezultaty",
          "- Jeżeli Pracując, czujesz wyczerpanie - wykorzystuj przerwy na interakcje z innymi",
        ],
      },
      {
        type: "ESFP",
        strengths: [
          "- Żywiołowa osobowość i entuzjazm, co przyciąga uwagę i pozytywnie wpływa na atmosferę",
          "- Silne zdolności interpersonalne i umiejętność nawiązywania relacji z innymi, co pomaga w budowaniu pozytywnych kontaktów",
          "- Zdolność do radzenia sobie z sytuacjami stresowymi i działania w czasie rzeczywistym, co pomaga w skutecznym reagowaniu na zmieniające się warunki",
          "- Kreatywność i zdolność do wykorzystywania swojego zmysłu estetyki, co przyczynia się do twórczego podejścia i innowacyjnych rozwiązań",
          "- Żywiołowa i ekspresywna komunikacja, co ułatwia nawiązywanie kontaktu z innymi i przekazywanie informacji",
        ],
        weaknesses: [
          "- Tendencja do bycia zbyt impulsywnym i podejmowania pochopnych decyzji, co może prowadzić do nieprzemyślanych konsekwencji",
          "- Skłonność do bycia zbyt zależnym od innych opinii i potrzeb, co może prowadzić do braku autonomiczności",
          "- Skłonność do bycia zbyt niecierpliwym i niezorganizowanym, co może prowadzić do niedopracowanych zadań",
          "- Tendencja do bycia zbyt niekonsekwentnym i trudności w długoterminowym planowaniu, co może utrudniać osiąganie celów",
          "- Tendencja do bycia zbyt pobieżnym i trudności w skupieniu się na szczegółach, co może prowadzić do niedokładności",
        ],
        summary: [
          "- Staraj się zrównoważyć swoją ekspresywność z umiejętnością przewidywania skutków swoich działań",
          "- Bądź świadomy swoich własnych potrzeb i przekonań, aby zachować równowagę między uwzględnianiem innych a spełnianiem swoich celów",
          "- Ćwicz cierpliwość i organizację, aby efektywnie wykorzystywać swoje umiejętności reakcji na bieżące wyzwania",
          "- Uważaj na konsekwencję i pracuj nad długoterminowym planowaniem, aby skutecznie wykorzystać swoją kreatywność",
          "- Pracuj nad skupianiem się na szczegółach i uważnym słuchaniu, aby efektywnie komunikować się i zrozumieć potrzeby innych",
        ],
      },
      {
        type: "ISTP",
        strengths: [
          "- Umiejętność skutecznego rozwiązywania problemów praktycznych i manualnych, co przyczynia się do precyzyjnej pracy",
          "- Zdolność do pracy pod presją i w stresie, co pozwala na efektywne działanie w trudnych sytuacjach",
          "- Sprawność manualna i umiejętność korzystania z narzędzi, co pomaga w realizacji praktycznych zadań",
          "- Zdolność do skupienia się na szczegółach i precyzyjnej analizy, co prowadzi do dokładności i dokładnego rozumienia sytuacji",
          "- Umiejętność radzenia sobie w trudnych warunkach i podejmowania szybkich decyzji, co przyczynia się do efektywnego działania",
        ],
        weaknesses: [
          "- Tendencja do bycia zbyt skrytym i niechętnym do dzielenia się informacjami, co może utrudniać współpracę z innymi",
          "- Skłonność do bycia zbyt niecierpliwym i podejmowania pochopnych decyzji, co może prowadzić do błędów",
          "- Tendencja do bycia zbyt nieufnym wobec zmian i nowych pomysłów,co może ograniczać innowacyjność",
          "- Skłonność do bycia zbyt zamyślonym i trudności w wyrażaniu swoich myśli, co może prowadzić do niejasności w komunikacji",
          "- Tendencja do bycia zbyt niecierpliwym i zbyt nieodpowiedzialnym, co może prowadzić do nieprzemyślanych działań",
        ],
        summary: [
          "- Staraj się być bardziej otwartym i dzielić się wiedzą, aby zbudować efektywną współpracę",
          "- Ćwicz cierpliwość i staraj się dokładniej analizować sytuację przed podjęciem decyzji",
          "- Bądź bardziej otwarty na nowe pomysły i eksperymenty, aby rozwijać swoją kreatywność",
          "- Pracuj nad komunikacją i wyrażaniem swoich myśli w sposób klarowny, aby zapewnić efektywną wymianę informacji",
          "- Uważaj na konsekwencje swoich działań i pracuj nad rozwijaniem odpowiedzialności.",
        ],
      },
      {
        type: "ISFP",
        strengths: [
          "- Wrażliwość i empatia, co pozwala na budowanie silnych relacji z klientami i zrozumienie ich potrzeb",
          "- Kreatywność i umiejętność myślenia poza utartymi schematami, co pomaga w tworzeniu innowacyjnych rozwiązań dla klientów",
          "- Umiejętność wyrażania emocji i komunikowania się w sposób autentyczny, co przyczynia się do budowania zaufania u klientów",
          "- Uważność na szczegóły i dbałość o estetykę, co przekłada się na prezentację nieruchomości w atrakcyjny sposób",
          "Zdolność do pracy w zespole i współpracy, co przyczynia się do efektywnej realizacji projektów nieruchomościowych",
        ],
        weaknesses: [
          +"- Skłonność do bycia zbyt niezdecydowanym i trudności w podejmowaniu szybkich decyzji, co może prowadzić do opóźnień",
          "- Skłonność do bycia zbyt nieufnym wobec zmian i nowych pomysłów, co może ograniczać rozwój i adaptację",
          "- Tendencja do bycia zbyt wrażliwym na krytykę i trudności w radzeniu sobie z konfliktami, co może wpływać na efektywność negocjacji",
          "- Tendencja do bycia zbyt skupionym na detalach i trudności w patrzeniu na szerszy kontekst, co może utrudniać podejmowanie strategicznych decyzji",
          "- Skłonność do bycia zbyt nieśmiałym i trudności w wyrażaniu swojego zdania, co może ograniczać wpływ na proces podejmowania decyzji",
        ],
        summary: [
          "- Pracuj nad rozwijaniem pewności siebie i podejmowaniem decyzji w sposób bardziej zdecydowany, aby osiągnąć większą efektywność",
          "- Bądź bardziej otwarty na nowe pomysły i eksperymenty, aby rozwijać swoją kreatywność i adaptować się do zmieniających się warunków",
          "- Pracuj nad radzeniem sobie z krytyką i konfliktami, rozwijając zdolności do asertywnego komunikowania się i rozwiązywania konfliktów",
          "- Staraj się utrzymywać równowagę między dbałością o szczegóły a zdolnością do patrzenia na całość, aby podejmować bardziej strategiczne decyzje",
          "- Ćwicz asertywność i wyrażanie swoich pomysłów w sposób pewny siebie, aby efektywnie przyczyniać się do procesu podejmowania decyzji.",
        ],
      },
    ],
  },
  {
    personalityTypes: "ROZUMOWCY cholerycy",
    types: [
      {
        type: "INTJ",
        strengths: [
          "- Silne umiejętności analityczne i logiczne myślenie, co pozwala na precyzyjne i efektywne przetwarzanie informacji",
          "- Doskonała zdolność do planowania i organizacji, co pomaga w skutecznym zarządzaniu projektami i działaniami",
          "- Kreatywność i zdolność do innowacyjnego myślenia, co przyczynia się do znalezienia unikalnych rozwiązań",
          "- Umiejętność skupienia na celu i wytrwałość w dążeniu do osiągnięcia zamierzonych rezultatów",
          "- Wysoka samodyscyplina i zdolność do samodzielnego działania, co przekłada się na efektywność w realizacji zadań",
        ],
        weaknesses: [
          "- Tendencja do przejmowania się detalami i analizowania zbyt wielu aspektów, co może prowadzić do zbyt długiego procesu podejmowania decyzji",
          "- Skłonność do nadmiernego perfekcjonizmu i niezadowolenia z osiągniętych rezultatów, co może spowalniać postęp",
          "- Skłonność do zamykania się w swojej wizji i nieuwzględniania perspektyw innych osób, co może prowadzić do braku współpracy",
          "- Tendencja do niecierpliwości wobec innych, którzy nie nadążają za ich myślami, co może prowadzić do frustracji",
          "- Tendencja do zamykania się w sobie i nieujawniania emocji, co może utrudniać nawiązywanie i utrzymywanie relacji",
        ],
        summary: [
          "- Staraj się utrzymać równowagę między analizą a działaniem, aby uniknąć przeciągania procesu decyzyjnego",
          "- Uważaj, aby nie być zbyt surowym dla siebie i doceniaj osiągnięte rezultaty",
          "- Bądź otwarty na perspektywy innych i poszukuj współpracy, aby osiągnąć jeszcze lepsze rezultaty",
          "- Wykazuj cierpliwość i wspieraj innych w ich procesie, aby zachować efektywną współpracę",
          "- Pracuj nad świadomością emocji i aktywnie pracuj nad budowaniem i utrzymywaniem relacji z innymi",
        ],
      },
      {
        type: "INTP",
        strengths: [
          "- Silne zdolności analityczne i logiczne myślenie, co pozwala na głębokie zrozumienie problemów i szukanie kreatywnych rozwiązań",
          "- Zdolność do krytycznego myślenia i zdrowego sceptycyzmu, co pomaga w ocenie informacji i unikaniu pułapek myślenia",
          "-Kreatywność i zdolność do generowania innowacyjnych pomysłów, co przyczynia się do twórczych rozwiązań",
          "-Otwartość na nowe idee i chęć zgłębiania wiedzy, co przyczynia się do ciągłego rozwoju intelektualnego",
          "-Samodzielność i zdolność do pracy w pojedynkę, co pozwala na skoncentrowanie się na zadaniach i projektach",
        ],
        weaknesses: [
          `- Tendencja do analizowania zbyt wielu perspektyw i wpadania w pułapki analizy "paraliżu przez analizę"`,
          "- Skłonność do bycia zbyt krytycznym wobec innych i nieakceptowania poglądów, co może utrudniać współpracę",
          "- Tendencja do bycia niezorganizowanym i braku skupienia, co może utrudniać realizację pomysłów",
          "- Skłonność do zbytniego zanurzenia w teorii i analizowania szczegółów, co może utrudniać praktyczne zastosowanie wiedzy",
          "- Tendencja do bycia zbyt zamkniętym emocjonalnie i nieuwzględniania perspektyw innych osób, co może prowadzić do trudności w relacjach",
        ],
        summary: [
          "- Staraj się utrzymać równowagę między analizą a działaniem, aby uniknąć przeciągania procesu podejmowania decyzji",
          "- Bądź otwarty na różne perspektywy i szukaj wspólnego gruntu, aby osiągnąć lepsze rezultaty",
          "- Staraj się rozwijać umiejętności organizacyjne i skupiać się na konkretnych celach, aby efektywnie realizować swoje pomysły",
          "- Utrzymuj równowagę między teorią a praktyką, aby efektywnie wykorzystać swoją wiedzę w realnym świecie",
          "- Pracuj nad rozwijaniem umiejętności komunikacyjnych i empatii, aby lepiej nawiązywać i utrzymywać relacje z innymi",
        ],
      },
      {
        type: "ENTJ",
        strengths: [
          "- Silne umiejętności przywódcze i zdolność do organizacji, co pozwala na skuteczne zarządzanie projektem lub zespołem",
          "- Zdolność do strategicznego myślenia i podejmowania szybkich decyzji, co przyczynia się do osiągania celów",
          "- Silna determinacja i wytrwałość w dążeniu do osiągnięcia sukcesu, co przyczynia się do realizacji ambitnych celów",
          "- Umiejętność skutecznego komunikowania się i przekonywania innych, co ułatwia negocjacje i sprzedaż",
          "- Zdolność do podejmowania ryzyka i innowacyjnego myślenia, co przyczynia się do tworzenia nowych możliwości",
        ],
        weaknesses: [
          "- Skłonność do dominacji i zbytniego narzucania swojej woli, co może negatywnie wpływać na współpracę z innymi",
          "- Tendencja do ignorowania lub pomijania szczegółów, co może prowadzić do niedociągnięć w realizacji planów",
          "- Skłonność do zbyt dużego obciążenia pracą i ignorowania potrzeb odpoczynku i równowagi życia",
          "- Tendencja do bycia zbyt asertywnym lub dominującym w komunikacji, co może zniechęcać innych do współpracy",
          "- Skłonność do bycia zbyt upartym lub niechętnym do akceptacji innych pomysłów, co może ograniczać kreatywność i współpracę",
        ],
        summary: [
          "- Bądź otwarty na perspektywy innych i szanuj ich wkład, aby tworzyć efektywne i harmonijne relacje",
          "- Pamiętaj o konieczności uwzględniania i analizowania szczegółów, aby osiągnąć kompletność i precyzję w działaniu",
          "- Pamiętaj o znaczeniu równowagi i dbaj o swoje zdrowie oraz równowagę między pracą a życiem osobistym",
          "- Ćwicz umiejętność empatycznego słuchania i elastycznego dostosowywania swojego stylu komunikacji, aby osiągnąć lepsze rezultaty",
          "- Bądź elastyczny i otwarty na różnorodne perspektywy, aby wykorzystać potencjał innych i osiągnąć innowacyjne rezultaty",
        ],
      },

      {
        type: "ENTP",
        strengths: [
          "- Zdolność do generowania nowych i kreatywnych pomysłów, co przyczynia się do innowacyjności i rozwiązywania problemów",
          "- Silna umiejętność perswazji i przekonywania innych, co ułatwia negocjacje i wpływanie na decyzje",
          "- Zdolność do elastycznego myślenia i adaptacji do zmieniających się sytuacji, co przyczynia się do skuteczności w dynamicznym środowisku",
          "- Umiejętność rozwiązywania problemów i analizy sytuacji z różnych perspektyw, co ułatwia znalezienie efektywnych rozwiązań",
          "- Energiczne i ekspresywne podejście do komunikacji, co przyciąga uwagę i angażuje słuchaczy",
        ],
        weaknesses: [
          "- Tendencja do bycia zbyt teoretycznym i skupiania się na ideach, co może prowadzić do braku realizacji i konkretnych działań",
          "- Skłonność do bycia zbyt argumentacyjnym lub upartym, co może wpływać negatywnie na relacje interpersonalne",
          "- Tendencja do bycia zbyt impulsywnym i podejmowania pochopnych decyzji, co może prowadzić do nieprzemyślanych działań",
          "- Skłonność do zbytniego krytycyzmu i wykorzystywania argumentów przeciwko innym, co może negatywnie wpływać na atmosferę współpracy",
          "- Tendencja do bycia zbyt rozproszonym i trudności w skupieniu się na jednym zadaniu, co może prowadzić do niedokończonych projektów",
        ],
        summary: [
          "- Staraj się wykorzystywać swoją kreatywność do praktycznych rozwiązań i realizacji celów",
          "- Bądź świadomy potrzeby słuchania i szanowania opinii innych, aby budować silne relacje i osiągać wspólne cele",
          "- Pamiętaj o potrzebie analizowania i oceny przed podjęciem decyzji, aby osiągnąć lepsze rezultaty",
          "- Staraj się wykorzystywać swoje zdolności analityczne do konstruktywnej krytyki i wspierania innych, aby osiągnąć lepsze rezultaty",
          "- Ćwicz koncentrację i zarządzanie czasem, aby wykorzystać swoje umiejętności komunikacyjne w sposób efektywny i zrealizować cele",
        ],
      },
    ],
  },
  {
    personalityTypes: "WIZJONERZY melancholicy",
    types: [
      {
        type: "INFJ",
        strengths: [
          "- Empatia i zrozumienie emocji innych, co przyczynia się do budowania głębokich i wartościowych relacji z klientami",
          "- Intuicja i zdolność do spostrzegania ukrytych motywacji i potrzeb klientów, co pomaga w dopasowaniu odpowiednich ofert nieruchomości",
          "- Kreatywność i umiejętność myślenia poza utartymi schematami, co pozwala na znalezienie unikalnych rozwiązań dla klientów",
          "- Zaangażowanie i oddanie, co przekłada się na wysoką jakość obsługi klienta",
          "- Wizjonerskie podejście i zdolność do planowania długoterminowego, co przyczynia się do sukcesu w projektach nieruchomościowych",
        ],
        weaknesses: [
          "- Tendencja do przejmowania się zbytnio potrzebamiinnych i trudności w wyrażaniu swoich własnych potrzeb",
          "- Skłonność do zbyt mocnego polegania na intuicji i trudności w uwzględnianiu faktów i dowodów",
          "- Tendencja do bycia zbyt perfekcjonistycznym i trudności w zakończeniu projektów z powodu ciągłego doskonalenia",
          "- Skłonność do przepracowywania się i trudności w zachowaniu równowagi między pracą a życiem prywatnym",
          "- Tendencja do bycia zbyt krytycznym wobec siebie i innych, co może hamować innowacyjność i współpracę zespołową",
        ],
        summary: [
          "- Utrzymuj zdrową równowagę między dbaniem o potrzeby klientów a wyrażaniem swoich własnych potrzeb",
          "- Pracuj nad równoważeniem intuicji z racjonalnym podejściem, aby podejmować dobrze uzasadnione decyzje",
          "- Uważaj na perfekcjonizm i pracuj nad umiejętnością zakończania projektów, aby efektywnie wykorzystać swoją kreatywność",
          "- Utrzymuj zdrową równowagę między pracą a życiem prywatnym, aby uniknąć wypalenia zawodowego",
          "- Pracuj nad akceptacją i konstruktywnym podejściem do siebie i innych, aby rozwijać innowacyjność i efektywną współpracę",
        ],
      },
      {
        type: "INFP",
        strengths: [
          "- Wrażliwość i empatia, co pomaga w nawiązywaniu głębokich i autentycznych relacji z klientami",
          "- Kreatywność i umiejętność myślenia poza utartymi schematami, co pozwala na znalezienie unikalnych rozwiązań dla klientów",
          "- Zdolność do głębokiego rozumienia potrzeb klientów i dopasowywania się do nich, co prowadzi do satysfakcji klientów",
          "- Wewnętrzna motywacja i zaangażowanie w pracę, co przekłada się na wysoką jakość obsługi klienta",
          "- Lojalność i oddanie klientom, co buduje długotrwałe relacje biznesowe",
        ],
        weaknesses: [
          "- Tendencja do zbyt dużego angażowania się emocjonalnie, co może utrudniać podejmowanie obiektywnych decyzji",
          "- Skłonność do bycia zbyt niezdecydowanym i trudności w podejmowaniu ostatecznych decyzji",
          "- Tendencja do unikania konfrontacji i trudności w negocjacjach, co może prowadzić do niewykorzystania pełnego potencjału",
          "- Skłonność do bycia zbyt samokrytycznym i trudności w docenianiu swoich osiągnięć",
          "- Tendencja do zbytniego perfekcjonizmu i trudności w zakończeniu projektów z powodu ciągłego doskonalenia",
        ],
        summary: [
          "- Utrzymuj zdrową równowagę między empatią a zachowaniem obiektywności w podejmowaniu decyzji",
          "- Pracuj nad umiejętnością podejmowania decyzji i zaufaj swoim intuicjom",
          "- Ćwicz umiejętność asertywności i negocjacji, aby efektywnie reprezentować interesy klientów",
          "- Uznawaj i celebrować swoje sukcesy oraz pracuj nad budowaniem pewności siebie",
          "- Uważaj na perfekcjonizm i naucz się zakończyć projekty, aby efektywnie wykorzystać swoje zaangażowanie",
        ],
      },
      {
        type: "ENFJ",
        strengths: [
          "- Silne umiejętności komunikacyjne i zdolność do nawiązywania bliskich relacji z klientami",
          "- Empatia i zrozumienie, co pomaga w identyfikowaniu potrzeb klientów i dostosowywaniu się do nich",
          "- Umiejętność mobilizacji i inspiracji innych, co przyczynia się do budowania zaufania klientów",
          "- Silne zdolności organizacyjne i planistyczne, co przekłada się na sprawną obsługę klientów",
          "- Zaangażowanie w rozwój innych i umiejętność budowania zespołu, co przyczynia się do efektywności działania",
        ],
        weaknesses: [
          "- Tendencja do przeciążania się pracą i poświęcania własnych potrzeb na rzecz innych",
          "- Skłonność do przejmowania się krytyką i trudności w radzeniu sobie ze stresem",
          "- Czasami nadmierne zaangażowanie emocjonalne, co może prowadzić do trudności w utrzymaniu obiektywizmu",
          "- Tendencja do zbytniego skupiania się na potrzebach innych i ignorowania własnych ambicji",
          "- Czasami nadmierna wrażliwość na opinie innych, co może wpływać na poczucie własnej wartości",
        ],
        summary: [
          "- Pamiętaj o dbaniu o własne potrzeby i znalezienie równowagi między obsługą klientów aopieką nad sobą",
          "- Pracuj nad umiejętnościami radzenia sobie ze stresem i rozwojem odporności emocjonalnej",
          "- Pracuj nad utrzymaniem równowagi między zaangażowaniem emocjonalnym a zachowaniem obiektywizmu",
          "- Pamiętaj o własnych celach i marzeniach i angażuj się w działania, które prowadzą do ich realizacji",
          "- Pracuj nad budowaniem pewności siebie i wiary w siebie, aby efektywnie reprezentować swoje kompetencje",
        ],
      },
      {
        type: "ENFP",
        strengths: [
          "- Kreatywność i pomysłowość, co przyczynia się do twórczego podejścia do pracy jako agent nieruchomości",
          "- Zdolności interpersonalne i umiejętność budowania relacji z klientami, co sprzyja nawiązywaniu pozytywnych interakcji",
          "- Entuzjazm i energiczne podejście, co przyciąga uwagę klientów i potrafi ich zainspirować",
          "- Elastyczność i adaptacyjność, co pozwala na szybką reakcję na zmieniające się potrzeby klientów",
          "- Zdolność do motywowania innych i budowania pozytywnej atmosfery, co sprzyja efektywnej współpracy",
        ],
        weaknesses: [
          "- Tendencja do czasem utraty koncentracji i skupienia, co może wpływać na dokładność i wykonanie zadań",
          "- Tendencja do unikania konfrontacji i trudności w podejmowaniu trudnych rozmów",
          "- Czasami tendencja do skupiania się na idealistycznych wizjach, co może prowadzić do niedopasowania do rzeczywistości",
          "- Czasami trudności w podejmowaniu decyzji i zmienianiu kierunku, gdy sytuacja tego wymaga",
          "- Tendencja do nadmiernego zaangażowania emocjonalnego, co może prowadzić do wyczerpania i braku zrównoważenia",
        ],
        summary: [
          "- Pracuj nad utrzymaniem skupienia i organizacją pracy, aby osiągać lepsze rezultaty",
          "- Pracuj nad rozwijaniem umiejętności rozwiązywania konfliktów i radzenia sobie z trudnymi sytuacjami",
          "- Pamiętaj o utrzymaniu równowagi między idealizmem a praktycznością, aby osiągać realistyczne cele",
          "- Pracuj nad rozwijaniem umiejętności podejmowania decyzji i odwagi w zmienianiu kierunku, gdy jest to konieczne",
          "- Pamiętaj o dbaniu o swoje emocjonalne i fizyczne dobrostan, aby efektywnie obsługiwać klientów",
        ],
      },
    ],
  },
  {
    personalityTypes: "WIZJONERZY melancholicy",
    types: [
      {
        type: "ISTJ",
        strengths: [
          "- Wierność i odpowiedzialność, co przekłada się na solidne wykonywanie obowiązków i zobowiązań jako agent nieruchomości",
          "- Skrupulatność i dbałość o szczegóły, co przyczynia się do dokładności w dokumentacji i przekazywaniu informacji klientom",
          "- Organizacja i planowanie, co pozwala na skuteczne zarządzanie harmonogramem i zadaniem jako agent nieruchomości",
          "- Lojalność i zaufanie, co przyczynia się do budowania długoterminowych relacji z klientami",
          "- Dyscyplina i sumienność, co wpływa na terminowe i rzetelne wykonywanie zadań jako agent nieruchomości",
        ],
        weaknesses: [
          "- Tendencja do zbytniego przywiązania do rutyny i oporu wobec zmian, co może utrudniać adaptację do nowych sytuacji",
          "- Czasami tendencja do nadmiernego zwracania uwagi na szczegóły i tracenia perspektywy ogólnej sytuacji",
          "- Czasami trudności w elastycznym reagowaniu na nieprzewidziane sytuacje, co może prowadzić do frustracji",
          "- Tendencja do niechęci do ryzyka i unikania podejmowania nowych wyzwań, co może ograniczać możliwości rozwoju",
          "- Czasami trudności w radzeniu sobie z niepewnością i presją, co może prowadzić do stresu",
        ],
        summary: [
          "- Staraj się być bardziej elastycznym i otwartym na zmiany, aby lepiej reagować na potrzeby klientów",
          "- Pamiętaj o zachowaniu równowagi między szczegółami a większym obrazem, aby skutecznie realizować cele",
          "- Pracuj nad rozwijaniem umiejętności adaptacji i elastycznego podejścia do zmian",
          "- Staraj się otwierać na nowe możliwości i podejmować kontrolowane ryzyko, aby osiągnąć większe sukcesy",
          "- Pracuj nad rozwijaniem umiejętności radzenia sobie z stresem i otwierania się na nowe wyzwania",
        ],
      },
      {
        type: "ISFJ",
        strengths: [
          "- Troska i empatia, co przekłada się na dobrą opiekę nad klientami i zdolność do budowania trwałych relacji",
          "- Dokładność i skrupulatność, co przyczynia się do precyzyjnego przekazywania informacji i załatwiania formalności w roli agenta nieruchomości",
          "- Lojalność i zaangażowanie, co wpływa na budowanie długotrwałych relacji z klientami i ich zaufanie",
          "- Organizacja i planowanie, co pozwala na skuteczne zarządzanie harmonogramem i zadaniem jako agent nieruchomości",
          "- Uważność i dbałość o potrzeby innych, co pomaga w identyfikowaniu i spełnianiu oczekiwań klientów",
        ],
        weaknesses: [
          "- Tendencja do nadmiernego przywiązania do tradycji i oporu wobec zmian, co może utrudniać adaptację do nowych trendów",
          "- Czasami trudności w podejmowaniu szybkich decyzji, zbytnie zastanawianie się nad szczegółami",
          "- Tendencja do nadmiernej ostrożności i unikania ryzyka, co może ograniczać szanse na rozwój",
          "- Czasami trudności w radzeniu sobie z nieprzewidzianymi sytuacjami i presją, co może prowadzić do stresu",
          "- Czasami trudności w wyrażaniu swoich potrzeb i asertywności w negocjacjach",
        ],
        summary: [
          "- Pracuj nad elastycznością i otwartością na zmiany, aby lepiej sprostać oczekiwaniom klientów",
          "- Pracuj nad równowagą między analizą szczegółów a podejmowaniem szybkich decyzji",
          "- Staraj się bardziej otwierać na nowe możliwości i kontrolowanie ryzyka, aby osiągnąć większe sukcesy",
          "- Pracuj nad rozwojem umiejętności elastycznego reagowania na zmiany i radzenia sobie ze stresem",
          "- Pracuj nad rozwijaniem umiejętności wyrażania swoich potrzeb i asertywnego negocjowania",
        ],
      },
    ],
  },
];

export const descSummary =
  "Należy pamiętać, że powyższe zestawienie opisuje ogólne tendencje dla Twojego typu, a każda osoba może mieć swoje indywidualne cechy i preferencje. Ważne jest dostosowywanie swojego stylu pracy do konkretnych klientów i sytuacji, wykorzystywanie swoich mocnych stron i dążenie do rozwoju w obszarach, które mogą wymagać większej uwagi.";
