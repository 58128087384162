export const descTemperamentSJ = `SJ – Protektorzy "Obrońcy"
(SJ) są niezawodni, altruistyczni i uczciwi. Kierują się ścisłą etyką pracy i kładą duży nacisk na
pomaganie innym i służenie społeczności. Obrońcy są utalentowanymi przywódcami ze względu na
ich naturalną zdolność do organizowania się, planowania i strategii. Rozwijają się w sytuacjach, w
których wiedzą, czego się oczekuje. Doceniają zasady i strukturę.

Obrońcy są spostrzegawczy i zmotywowani do utrzymania szeroko pojętego bezpieczeństwa. Bardzo
zmotywowani, wiedzą, kim są i czują się komfortowo we własnej skórze. Siłą napędową w ich życiu
jest okazywanie miłości poprzez dbanie o swoich bliskich w praktyczny i subtelny sposób. Mają
tendencję do ciężkiej pracy - jest to sprowokowane przez ich silne wewnętrzne zasady, takie jak
pracowitość i szacunek. Rzetelni, uczciwi i pracowici, są doskonałymi liderami.`;

export const descTemperamentNT = `NT – "Intelektualiści"
Intelektualiści (NT) są inteligentni, niezależni i zdeterminowani. Osiągają wysokie wyniki, dążąc
nie tylko do zdobywania, ale także do opanowywania dużych ilości informacji. Są 
samowystarczalne, logiczne i wartościują rozum. Starają się wiedzieć wszystko i będą 
kwestionować wszystko. Ich żywe zainteresowanie badaniami czyni z nich świetnych badaczy i 
wynalazców.

Intelektualiści są introspekcyjni, logiczni i nieustannie poszukują wiedzy. Z natury ciekawi
i pomysłowi, i racjonalni. Pomimo racjonalnego sposobu myślenia i podejścia "twardo stąpających
po ziemi", często są bardzo pomysłowi i chętnie zgłębiają sprawy na głębszym poziomie. 
Zamiłowanie do myślenia, eksperymentowania i spekulacji sprawia, że te osobowości są cennym
nabytkiem w każdej społeczności.Głupie pytania nie istnieją dla intelektualistów
– zawsze są gotowi do burzy mózgów.`;

export const descTemperamentSP = `SP – Twórcy "Twórcy"
(SP) są naturalnie artystyczni, odważni i elastyczni. Doceniają piękno natury, mody i dekoracji. Ich
żądna przygód natura sprawia, że są pobudliwi, energiczni i spontaniczni. Kierując się ciekawością i
zabawą, Twórcy są gotowi spróbować niemal wszystkiego. Są sympatyczne i popularne. Uwielbiają
opowiadać dobrą historię.

Twórcy są samowystarczalnymi odkrywcami, którzy nie mają nic przeciwko opuszczeniu szlaku bez
mapy podczas jednodniowej wędrówki. Ich impulsywność uczy ich łatwego dostosowywania się do
każdej sytuacji. Ludzie o tych osobowościach nie lubią obowiązków i nic nie może ich bardziej
frustrować niż prowadzenie monotonnego życia. Dlatego lubią urozmaicać swoje życie i typowe dla
nich jest to, że zawsze są w ruchu. Nieustannie szukając nowych doświadczeń. Trudno się nudzić w
ich towarzystwie.`;

export const descTemperamentNF = `NF – "Wizjonerzy"
Wizjonerzy (NF) są empatyczni, hojni i oryginalni. Są to troskliwe osoby, które są nie tylko wrażliwe na
uczucia innych, ale także bardzo biegłe w ich identyfikowaniu. Są idealistyczni i kierują się
wartościami, w które głęboko wierzą i których bronią. Wizjonerzy pragną zrozumieć siebie i być
zrozumianymi takimi, jakimi naprawdę są.

Wizjonerzy są introspekcyjni, intuicyjni i wysoce idealistyczni. Są współczującymi ludźmi, którzy chcą
pomagać innym i czynić świat lepszym miejscem. Dla nich to życzliwość jest kluczowa i chętnie będą
współpracować, a nie konkurować, jak intelektualiści. Naturalnie empatyczni, emocjonalni, wolą
głębokie rozmowy od small talk. Bardzo optymistyczni, troskliwi i życzliwi, nie mają problemu z
otwartością na świat i ludzi, ale powinni uważać aby uniknąć zranienia lub wykorzystania przez
niektórych. Głęboka intuicja którą się cechują może być pomocna w rozróżnianiu komu warto ufać i
pozostać bardzo otwartym a komu nie.`;

export const allTemperamentTypes = [
  ["SJ", ["ESTJ", "ESFJ", "ISTJ", "ISFJ"]],
  ["SP", ["ESTP", "ESFP", "ISTP", "ISFP"]],
  ["NT", ["ENTJ", "ENTP", "INTJ", "INTP"]],
  ["NF", ["ENFJ", "ENFP", "INFJ", "INFP"]],
];

function searchTemperamentElement(userType) {
  const foundElement = allTemperamentTypes.find((element) =>
    element[1].includes(userType[0])
  );
  return foundElement;
}

const getUserTemperament = (userType) => {
  const element = searchTemperamentElement(userType, allTemperamentTypes);
  switch (element[0]) {
    case "SJ":
      return descTemperamentSJ;
    case "NT":
      return descTemperamentNT;
    case "SP":
      return descTemperamentSP;
    case "NF":
      return descTemperamentNF;
  }
};
export default getUserTemperament;
