import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import createElementCanvas from "./createElementCanvas.js";
import annotationPlugin from "chartjs-plugin-annotation";
import store from "../../store";
Chart.register(annotationPlugin);

const renderTemperament = (userType, id) => {
  return new Promise((resolve, reject) => {
    const personalityGroups = store.getters["questions/personalityData"];
    const ctx = createElementCanvas(id, "chart-container-personality-job");
    const resultTypeSJ = calculateGenderTotals(personalityGroups.SJ);
    const resultTypeSP = calculateGenderTotals(personalityGroups.SP);
    const resultTypeNT = calculateGenderTotals(personalityGroups.NT);
    const resultTypeNF = calculateGenderTotals(personalityGroups.NF);
    let userGroup = "";
    for (const group in personalityGroups) {
      const typesInGroup = personalityGroups[group].map((item) => item.type);
      if (typesInGroup.includes(userType)) {
        userGroup = group;
        break;
      }
    }

    const data = {
      labels: [
        "SJ Obrończyni",
        "SP Twórcy",
        "NT Intelektualiści",
        "NF Wizjonerzy",
      ],
      datasets: [
        {
          label: "Mężczyźni",
          backgroundColor: "rgba(60, 164, 255, 0.8)",
          data: [
            Math.min(resultTypeSJ.man / 2, 50).toFixed(1),
            Math.min(resultTypeSP.man / 2, 50).toFixed(1),
            Math.min(resultTypeNT.man / 2, 50).toFixed(1),
            Math.min(resultTypeNF.man / 2, 50).toFixed(1),
          ],
        },
        {
          label: "Kobiety",
          backgroundColor: "rgba(255, 51, 51, 0.8)",
          data: [
            Math.min(resultTypeSJ.woman / 2, 50).toFixed(1),
            Math.min(resultTypeSP.woman / 2, 50).toFixed(1),
            Math.min(resultTypeNT.woman / 2, 50).toFixed(1),
            Math.min(resultTypeNF.woman / 2, 50).toFixed(1),
          ],
        },
      ],
    };
    let ntBarIndex = findMatchingLabelIndex(data.labels, userGroup);
    const myChart = new Chart(ctx, {
      type: "bar",
      data: data,
      plugins: [ChartDataLabels, annotationPlugin],
      options: {
        scales: {
          x: {
            stacked: true,
            title: {
              display: true,
              text: "Twój Temperament w populacji",
              font: {
                size: 16,
              },
            },
          },
          y: {
            stacked: true,
            title: {
              display: true,
              text: "Populacja",
              font: {
                size: 16,
              },
            },
            stepSize: 5,
          },
        },
        plugins: {
          title: {
            display: true,
            text: `Temperament ${userType}`,
            font: {
              size: 25,
            },
          },
          annotation: {
            annotations: {
              label1: {
                rotation: -70,
                type: "label",
                yAdjust: -50,
                xMin: ntBarIndex - 0.5,
                xMax: ntBarIndex + 0.5,
                backgroundColor: "rgba(245,245,245)",
                content: ["TWÓJ WYNIK"],
                font: {
                  size: 20,
                },
              },
              box1: {
                type: "box",
                xMin: ntBarIndex - 0.5,
                xMax: ntBarIndex + 0.5,
                yMin: 0,
                yMax: 100,
                backgroundColor: "rgba(255, 255, 0, 0.1)",
              },
            },
          },

          datalabels: {
            color: "black",
            font: {
              size: 16,
              weight: "bold",
            },
            align: "center",
            formatter: function (value) {
              return value + "%";
            },
          },
        },
        animation: {
          onComplete: function () {
            resolve();
          },
        },
      },
    });
  });
};

export default renderTemperament;

const findMatchingLabelIndex = (labels, userGroup) => {
  const prefix = userGroup.substring(0, 2);
  return labels.findIndex((label) => label.startsWith(prefix));
};

function calculateGenderTotals(personalityData) {
  const totals = {
    man: 0,
    woman: 0,
  };

  personalityData.forEach((item) => {
    totals.man += item.man;
    totals.woman += item.woman;
  });

  return totals;
}
