import {
  descIntrovertExtravert,
  descMoreIntrovert,
  descMoreExtravert,
  descIntrovert,
  descExtravert,
  descSensingIntuition,
  descSensing,
  descIntuition,
  descMoreSensing,
  descMoreIntuition,
  descThinkingFeeling,
  descThinking,
  descFeeling,
  descMoreThinking,
  descMoreFeeling,
  descJudgingPerceiving,
  descJudging,
  descPerceiving,
  descMoreJudging,
  descMorePerceiving,
} from "./descriptionCharts.js";
import descPairsTypes from "./descriptionTableCharts.js";
import descTypes from "./descTypes.js";
import { formatTextWithBoldStyles } from "./formatTextBoldStyles.js";
import iconExtravert from "../../assets/icons/extrovert.png";
import iconIntrovert from "../../assets/icons/introvert.png";
import iconThinking from "../../assets/icons/thinking.png";
import iconFeeling from "../../assets/icons/feeling.png";
import iconSensing from "../../assets/icons/sensing.png";
import iconIntuition from "../../assets/icons/intuition.png";
import iconJudging from "../../assets/icons/judging.png";
import iconPerceiving from "../../assets/icons/perceiving.png";
//EXTRVERT VS INTROVERT
export const descriptionExtravertIntrovertPosition = (
  pageWidth,
  pageHeight,
  doc
) => {
  const textExtravertIntrovertPositionX = 15;
  const textExtravertIntrovertPositionY = 20;
  formatTextWithBoldStyles(
    doc,
    descIntrovertExtravert,
    textExtravertIntrovertPositionX,
    textExtravertIntrovertPositionY
  );

  const positionXdescTable = 25;
  const positionYdescTable = 155;

  const splitTextDescIntrovert = doc.splitTextToSize(descIntrovert, 180, {
    lineHeightFactor: 2,
  });
  const splitTextDescExtravert = doc.splitTextToSize(descExtravert, 180);

  descTypes(
    doc,
    splitTextDescExtravert,
    splitTextDescIntrovert,
    positionXdescTable,
    positionYdescTable,
    "EKSTRAWERTYCY",
    "INTROWERTYCY",
    iconExtravert,
    iconIntrovert
  );
  const positionXTable = 20;
  const positionYTable = 110;
  descPairsTypes(
    doc,
    descMoreExtravert,
    descMoreIntrovert,
    "EKSTRAWERTYCY",
    "INTROWERTYCY",
    positionXTable,
    positionYTable
  );
  doc.addPage();
};

//SENSING VS INTUITION
export const descriptionSensingIntuitionPosition = (
  pageWidth,
  pageHeight,
  doc
) => {
  const textSensingIntuitionPositionX = 15;
  const textSensingIntuitionPositionY = 20;
  formatTextWithBoldStyles(
    doc,
    descSensingIntuition,
    textSensingIntuitionPositionX,
    textSensingIntuitionPositionY
  );
  const positionXdescTable = 25;
  const positionYdescTable = 170;
  const splitTextDescSensing = doc.splitTextToSize(descSensing, 180);
  const splitTextDescIntuition = doc.splitTextToSize(descIntuition, 180);

  descTypes(
    doc,
    splitTextDescSensing,
    splitTextDescIntuition,
    positionXdescTable,
    positionYdescTable,
    "POZNAJĄCY",
    "INTUICYJNI ",
    iconSensing,
    iconIntuition
  );
  const positionXTable = 20;
  const positionYTable = 100;
  descPairsTypes(
    doc,
    descMoreSensing,
    descMoreIntuition,
    "POZNAJĄCY",
    "INTUICYJNI ",
    positionXTable,
    positionYTable
  );
};
//THINKING VS FEELING
export const descriptionThinkingFeelingPosition = (
  pageWidth,
  pageHeight,
  doc
) => {
  const textThinkingFeelingPositionX = 15;
  const textThinkingFeelingPositionY = 20;
  formatTextWithBoldStyles(
    doc,
    descThinkingFeeling,
    textThinkingFeelingPositionX,
    textThinkingFeelingPositionY
  );

  const positionXdescTable = 25;
  const positionYdescTable = 190;
  const splitTextDescThinking = doc.splitTextToSize(descThinking, 180);
  const splitTextDescFeeling = doc.splitTextToSize(descFeeling, 180);
  descTypes(
    doc,
    splitTextDescThinking,
    splitTextDescFeeling,
    positionXdescTable,
    positionYdescTable,
    "MYŚLĄCY",
    "ODCZUWAJĄCY",
    iconThinking,
    iconFeeling
  );
  const positionXTable = 20;
  const positionYTable = 80;
  descPairsTypes(
    doc,
    descMoreThinking,
    descMoreFeeling,
    "MYŚLĄCY",
    "ODCZUWAJĄCY ",
    positionXTable,
    positionYTable
  );
  doc.addPage();
};
// JUDGING VS PERCEIVING
export const descriptionJudgingPerceivingPosition = (
  pageWidth,
  pageHeight,
  doc
) => {
  const textJudgingPerceivingPositionX = 15;
  const textJudgingPerceivingPositionY = 20;
  formatTextWithBoldStyles(
    doc,
    descJudgingPerceiving,
    textJudgingPerceivingPositionX,
    textJudgingPerceivingPositionY
  );
  const positionXdescTable = 25;
  const positionYdescTable = 210;
  const splitTextDescJudging = doc.splitTextToSize(descJudging, 180);
  const splitTextDescPerceiving = doc.splitTextToSize(descPerceiving, 180);

  descTypes(
    doc,
    splitTextDescJudging,
    splitTextDescPerceiving,
    positionXdescTable,
    positionYdescTable,
    "OSĄDZAJĄCY",
    "OBSERWUJĄCY",
    iconJudging,
    iconPerceiving
  );

  const positionXTable = 20;
  const positionYTable = 100;
  descPairsTypes(
    doc,
    descMoreJudging,
    descMorePerceiving,
    "OSĄDZAJĄCY",
    "OBSERWUJĄCY",
    positionXTable,
    positionYTable
  );
};
