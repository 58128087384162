import axios from "axios";

const getLastReportId = async () => {
  try {
    const response = await axios.get(
      "https://api.imdm.codelabi.com/survey/lastId"
    );
    return response.data.LastReportId;
  } catch (error) {
    throw error;
  }
};

export default getLastReportId;
