import getters from "./getters.js";
import mutations from "./mutations.js";
import actions from "./actions.js";

export default {
  namespaced: true,
  state() {
    return {
      totalPoints: 280,
      questions: [
        {
          id: 1,
          question: "Chętniej…",
          descriptionA: "rozwiązujesz nowy, skomplikowany problem;",
          descriptionB:
            "pracujesz nad czymś, co robiłeś wcześniej lub co znasz z przeszłości;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 2,
          question: "Chętniej...",
          descriptionA: "pracujesz sam w cichym otoczeniu;",
          descriptionB: "jesteś tam, gdzie coś się dzieje;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 3,
          question: "Przy ocenianiu innych kierujesz się raczej...",
          descriptionA:
            "stałymi zasadami bardziej niż chwilowymi okolicznościami;",
          descriptionB:
            "chwilowymi okolicznościami bardziej niż stałymi zasadami;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 4,
          question: "Masz skłonność do wybierania w sposób...",
          descriptionA: "raczej staranny;",
          descriptionB: "dość impulsywny;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 5,
          question: "W towarzystwie lub na imprezie zazwyczaj rozmawiasz...",
          descriptionA: "z niewielką grupą ludzi, których dobrze znasz;",
          descriptionB:
            "z wieloma osobami włączając tych, których znasz mało lub wcale;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 6,
          question:
            "Jako dyrektor firmy wolałbyś uzyskać od swoich podwładnych...",
          descriptionA:
            "informację o tym, że ich dział pracował na korzyść firmy jako całości;",
          descriptionB: "sprawozdania, jak dział radzi sobie oddzielnie;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 7,
          question:
            "Jako menadżer pragnący przyjąć swojego asystenta postępujesz w następujący sposób...",
          descriptionA:
            "zastanawiasz się czy wasze osobowości będą zgodne lub czy będą się uzupełniać;",
          descriptionB:
            "przeanalizujesz związek pomiędzy zakresem obowiązków i zdolnościami kandydatów;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 8,
          question: "Kiedy pracujesz nad czymś to...",
          descriptionA:
            "wolisz raczej wszystko zakończyć lub dojść do pewnego etapu;",
          descriptionB:
            "często zostawiasz niezamknięte sprawy z możliwością wprowadzenia zmian;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 9,
          question: "Na imprezach w towarzystwie...",
          descriptionA: "raczej zostajesz dłużej, bo tak się lepiej bawisz;",
          descriptionB:
            "wychodzisz jak najprędzej, taki rodzaj rozrywki Cię wyczerpuje, jesteś tam z grzeczności;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 10,
          question: "Bardziej interesuje Cię to...",
          descriptionA: "co było;",
          descriptionB: "co może być;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 11,
          question:
            "Kiedy słuchasz, jak ktoś mówi o swoich sprawach, to zwykle próbujesz...",
          descriptionA:
            "odnieść to do swoich własnych doświadczeń i porównać, czy to Ci odpowiada;",
          descriptionB: "oceniać i analizować daną informację;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 12,
          question: "Kiedy nad czymś pracujesz, to...",
          descriptionA: "wolisz mieć orientację i kontrolę nad wszystkim;",
          descriptionB:
            "dopuszczasz możliwość sprawdzenia wszystkich możliwości;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 13,
          question: "Kiedy w domu lub biurze zadzwoni telefon, to zazwyczaj...",
          descriptionA: "uważasz, że to przeszkadza;",
          descriptionB: "odbierasz, nie przeszkadza Ci to;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 14,
          question: "Gorsze jest...",
          descriptionA: "chodzenie z głową w chmurach;",
          descriptionB: "chodzenie twardo po ziemi;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 15,
          question: "W stosunku do innych jesteś raczej...",
          descriptionA: "obiektywny;",
          descriptionB: "subiektywny;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 16,
          question: "Przeszkadza Ci, kiedy...",
          descriptionA: "„rozgrzebanych” jest kilka spraw na raz;",
          descriptionB: "wszystko już przygotowane;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 17,
          question: "Kiedy gdzieś telefonujesz...",
          descriptionA:
            "nie boisz się, że w trakcie rozmowy o czymś zapomnisz;",
          descriptionB: "zawsze się przygotowujesz - co i jak będziesz mówić;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 18,
          question:
            "Kiedy dyskutujesz z kolegami o problemach, to łatwe jest...",
          descriptionA: "widzieć sprawę w szerszym kontekście;",
          descriptionB:
            "spostrzec szczegóły i specyficzne cechy danej sytuacji;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 19,
          question: "Gdy pojawia się problem, wolisz:",
          descriptionA: "analizować;",
          descriptionB: "wczuwać się;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 20,
          question: "Często, kiedy zaczynasz coś...",
          descriptionA: "najpierw wszystko spisujesz i planujesz;",
          descriptionB:
            "nie robisz planów i pozwalasz, aby sprawy biegły swoim torem;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 21,
          question: "W towarzystwie innych osób raczej...",
          descriptionA: "sam nawiązujesz rozmowę;",
          descriptionB: "pozostawiasz innym inicjatywę;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 22,
          question:
            "Kiedy pracujesz nad jakimś zadaniem, to masz tendencję do...",
          descriptionA: "ciągłej i nieprzerwanej pracy;",
          descriptionB: "bardzo zintensyfikowanej pracy z przerwami;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 23,
          question: "W jakiej sytuacji czujesz się lepiej?",
          descriptionA: "jasnej, o stałej strukturze i planie;",
          descriptionB: "zmiennej,bez struktury, z niespodziankami;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 24,
          question: "Gorzej jest, kiedy ktoś...",
          descriptionA: "jest niesprawiedliwy;",
          descriptionB: "nie ma litości;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 25,
          question: "Twoją mocną strona jest...",
          descriptionA: "realne podejście do życia;",
          descriptionB: "wyobraźnia;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 26,
          question: "Kiedy zadzwoni telefon...",
          descriptionA: "biegniesz, aby odebrać;",
          descriptionB: "masz nadzieję, że odbierze ktoś inny;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 27,
          question: "W swoim postępowaniu kierujesz się bardziej...",
          descriptionA: "rozumem;",
          descriptionB: "sercem;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 28,
          question: "Bardziej podziwiasz zdolność do:",
          descriptionA: "metodycznego planowania i organizowania spraw;",
          descriptionB:
            "szybkiego adaptowania się i korzystania z nadarzających się okazji;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 29,
          question: "Kiedy przyjdzie Ci do głowy nowa myśl, to zazwyczaj...",
          descriptionA: "podchodzisz do niej z zapałem;",
          descriptionB: "zaczynasz na ten temat głębiej rozmyślać;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 30,
          question: "Powiesz o sobie, że jesteś raczej...",
          descriptionA: "dowcipny;",
          descriptionB: "praktyczny;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 31,
          question: "Wolisz usłyszeć...",
          descriptionA: "ostateczną i niezmienną opinię;",
          descriptionB: "próbną, wstępną opinię;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 32,
          question: "Większym błędem jest być:",
          descriptionA: "tolerancyjnym i zgodnym;",
          descriptionB: "bezkompromisowym i krytycznym;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 33,
          question: "Jesteś raczej...",
          descriptionA: "rannym ptaszkiem;",
          descriptionB: "sową;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 34,
          question: "Gorszą Cię osoby, które na rozmowę przychodzą...",
          descriptionA: "z wieloma niejasnymi, nieprzemyślanymi pomysłami;",
          descriptionB:
            "przedłużające rozmowę zajmując uwagę wieloma praktycznymi szczegółami;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 35,
          question: "Podczas pracy wolisz zajmować się...",
          descriptionA: "ideami, zasadami, pomysłami;",
          descriptionB: "ludźmi, uczestnikami;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 36,
          question: "W czasie weekendu zazwyczaj...",
          descriptionA: "planujesz, co będziesz robić;",
          descriptionB:
            "pozostawiasz sprawy własnemu biegowi, decyzje podejmujesz na bieżąco;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 37,
          question: "Podczas rozmów/ negocjacji, masz skłonność do...",
          descriptionA: "rozwijania swoich myśli w miarę tego jak mówisz;",
          descriptionB:
            "zabierania głosu jedynie po wcześniejszym przemyśleniu, co chcesz powiedzieć;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 38,
          question: "Czytasz zwykle...",
          descriptionA: "skupiając się na tym, co jest napisane;",
          descriptionB:
            "czytasz między wierszami i wiążesz słowa z innymi tematami i wątkami;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 39,
          question: "Kiedy musisz zdecydować coś pośpiesznie, to...",
          descriptionA: "czujesz dyskomfort i chciałbyś mieć więcej informacji;",
          descriptionB:
            "umiesz podjąć decyzje mając tylko te dane, które są dostępne;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 40,
          question: "Wołałbyś pracować dla firmy, która...",
          descriptionA: "dałaby Ci pracę z motywacją intelektualną;",
          descriptionB:
            "zainteresowałaby Cię przesłaniem i realizacją swoich celów;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 41,
          question: "Co bardziej Ci imponuje?",
          descriptionA:
            "logiczny, konkretny i bezsporny sposób myślenia i postępowania;",
          descriptionB: "dobre, ciepłe i harmonijne stosunki międzyludzkie;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 42,
          question: "W swoich pisemnych wypowiedziach wolisz styl...",
          descriptionA: "rzeczowy;",
          descriptionB: "obrazowy, metaforyczny;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 43,
          question: "Kiedy ktoś dzwoni do drzwi...",
          descriptionA: "jesteś rozdrażniony, że „ktoś znowu przylazł”;",
          descriptionB: "cieszysz się, że ktoś przyszedł Cię odwiedzić;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 44,
          question: "Wolisz...",
          descriptionA: "żeby rzeczy „same się działy”;",
          descriptionB: "zapewnić, aby wszystko było wcześniej przygotowane;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 45,
          question: "Bardziej charakterystyczne są dla Ciebie...",
          descriptionA: "liczne, powierzchowne związki z dużą liczbą osób;",
          descriptionB: "stałe i długotrwałe związki z niewielką grupą ludzi;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 46,
          question: "Czujesz się lepiej...",
          descriptionA: "po podjęciu końcowej decyzji;",
          descriptionB: "kiedy sprawy nie są jeszcze definitywnie zakończone;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 47,
          question: "Wolisz polegać na swoich...",
          descriptionA: "doświadczeniach;",
          descriptionB: "intuicji i przeczuciach;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 48,
          question: "Czujesz się pewniej...",
          descriptionA:
            "przy podejmowaniu decyzji logicznych: prawidłowe/ nieprawidłowe;",
          descriptionB: "wydając opinie wartościującą: dobre/ złe;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 49,
          question: "Chętniej podejmujesz decyzje...",
          descriptionA: "po zasięgnięciu opinii innych;",
          descriptionB: "przede wszystkim według własnego zdania;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 50,
          question: "Zazwyczaj wybierasz...",
          descriptionA: "skorzystanie ze sprawdzonej metody, którą znasz;",
          descriptionB:
            "wymyślanie i próbowanie, jakby coś zrobić inaczej i na nowo;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 51,
          question: "Chętniej zajmujesz się...",
          descriptionA: "tym co jest;",
          descriptionB: "rozważaniem różnych możliwości;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 52,
          question: "Do konkluzji i wniosków dochodzisz na podstawie...",
          descriptionA: "rzeczowego uzasadnienia i logicznej analizy;",
          descriptionB:
            " tego, co według Twego odczucia jest prawidłowe, w co wierzysz, co jest ludzkie i do przyjęcia;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 53,
          question: "Nie lubisz...",
          descriptionA:
            "wcześniej zaplanowanych spraw ze zobowiązującymi terminami;",
          descriptionB:
            "kiedy sprawy nie mają rygorystycznych terminów, są niezobowiązujące;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 54,
          question: "Najpiękniejsze chwile przeżywasz...",
          descriptionA:
            "z wieloma przyjaciółmi, z ludźmi, z którymi dobrze się czujesz, choć niekoniecznie znasz ich długo;",
          descriptionB: "z niewielką ilością osób, które długo i dobrze znasz;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 55,
          question: "Masz w zwyczaju...",
          descriptionA:
            "zapisywać w kalendarzu ważne spotkania i sprawy do załatwienia;",
          descriptionB: "ograniczać/ unikać tego typu zapisków;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
        {
          id: 56,
          question: "Postępujesz zwykle na zasadzie...",
          descriptionA:
            "wewnętrznego przeświadczenia i poczucia, że coś jest właściwe i nie musisz tego analizować;",
          descriptionB: "obiektywnych i sprawdzonych wniosków;",
          userAnswerA: 0,
          userAnswerB: 0,
        },
      ],
      personalityData: {
        SJ: [
          {
            type: "ESTJ",
            name: "The Administrator",
            man: 11.2,
            woman: 6.3,
            job: "Nadzorca",
            value: 13.0,
          },

          {
            type: "ISTJ",
            name: "The Archivist",
            man: 16.4,
            woman: 6.9,
            job: "Egzaminator",
            value: 8.5,
          },
          {
            type: "ISFJ",
            name: "The Defender",
            man: 8.1,
            woman: 19.4,
            job: "Obrońca",
            value: 7.0,
          },
          {
            type: "ESFJ",
            name: "The Caregiver",
            man: 7.5,
            woman: 16.9,
            job: "Kibic",
            value: 12.0,
          },
        ],
        SP: [
          {
            type: "ESTP",
            name: "The Daredevil",
            man: 5.6,
            woman: 3.0,
            job: "Perswadowacz",
            value: 10.0,
          },
          {
            type: "ESFP",
            name: "The Entertainer",
            man: 6.9,
            woman: 10.1,
            job: "Zabawiacz",
            value: 11.0,
          },
          {
            type: "ISTP",
            name: "The Tinkerer",
            man: 8.5,
            woman: 2.3,
            job: "Rzemieślnik",
            value: 6.0,
          },
          {
            type: "ISFP",
            name: "The Adventurer",
            man: 7.6,
            woman: 9.9,
            job: "Artysta",
            value: 6.0,
          },
        ],
        NT: [
          {
            type: "ENTJ",
            name: "The General",
            man: 2.7,
            woman: 0.9,
            job: "Szef",
            value: 4.0,
          },
          {
            type: "ENTP",
            name: "The Debater",
            man: 4.0,
            woman: 2.4,
            job: "Pomysłodawca",
            value: 4.5,
          },
          {
            type: "INTJ",
            name: "The Mastermind",
            man: 3.3,
            woman: 0.9,
            job: "Strateg",
            value: 1.5,
          },
          {
            type: "INTP",
            name: "The Logician",
            man: 4.8,
            woman: 1.7,
            job: "Inżynier",
            value: 2.5,
          },
        ],
        NF: [
          {
            type: "INFP",
            name: "The Mediator",
            man: 4.1,
            woman: 4.6,
            job: "Marzyciel",
            value: 2.0,
          },
          {
            type: "ENFJ",
            name: "The Guide",
            man: 1.6,
            woman: 3.3,
            job: "Mentor",
            value: 4.0,
          },
          {
            type: "ENFP",
            name: "The Optimist",
            man: 6.4,
            woman: 9.7,
            job: "Adwokat",
            value: 7.0,
          },
          {
            type: "INFJ",
            name: "The Sage",
            man: 1.2,
            woman: 1.6,
            job: "Powiernik",
            value: 1.0,
          },
        ],
      },
      //   {
      //     type: "ESTJ",
      //     name: "The Administrator",
      //     man: 11.2,
      //     woman: 6.3,
      //   },
      //   {
      //     type: "ESTP",
      //     name: "The Daredevil",
      //     man: 5.6,
      //     woman: 3.0,
      //   },
      //   {
      //     type: "ESFJ",
      //     name: "The Caregiver",
      //     man: 7.5,
      //     woman: 16.9,
      //   },
      //   {
      //     type: "ESFP",
      //     name: "The Entertainer",
      //     man: 6.9,
      //     woman: 10.1,
      //   },
      //   {
      //     type: "ENTJ",
      //     name: "The General",
      //     man: 2.7,
      //     woman: 0.9,
      //   },
      //   {
      //     type: "ENTP",
      //     name: "The Debater",
      //     man: 4.0,
      //     woman: 2.4,
      //   },
      //   {
      //     type: "ENFJ",
      //     name: "The Guide",
      //     man: 1.6,
      //     woman: 3.3,
      //   },
      //   {
      //     type: "ENFP",
      //     name: "The Optimist",
      //     man: 6.4,
      //     woman: 9.7,
      //   },
      //   {
      //     type: "ISTJ",
      //     name: "The Archivist",
      //     man: 16.4,
      //     woman: 6.9,
      //   },
      //   {
      //     type: "ISTP",
      //     name: "The Tinkerer",
      //     man: 8.5,
      //     woman: 2.3,
      //   },
      //   {
      //     type: "ISFJ",
      //     name: "The Defender",
      //     man: 8.1,
      //     woman: 19.4,
      //   },
      //   {
      //     type: "ISFP",
      //     name: "The Adventurer",
      //     man: 7.6,
      //     woman: 9.9,
      //   },
      //   {
      //     type: "INTJ",
      //     name: "The Mastermind",
      //     man: 3.3,
      //     woman: 0.9,
      //   },
      //   {
      //     type: "INTP",
      //     name: "The Logician",
      //     man: 4.8,
      //     woman: 1.7,
      //   },
      //   {
      //     type: "INFJ",
      //     name: "The Sage",
      //     man: 1.2,
      //     woman: 1.6,
      //   },
      //   {
      //     type: "INFP",
      //     name: "The Mediator",
      //     man: 4.1,
      //     woman: 4.6,
      //   },
      // ],
    };
  },
  getters,
  mutations,
  actions,
};
