export const descIntrovertExtravert = `EKSTRAWERSJA (E) – (I) INTROWERSJA
Ten wymiar osobowości pokazuje **jak preferujesz oddziaływać z otoczeniem i gdzie kierujesz swoją energię -** bardziej na zewnątrz: do ludzi i świata czy bardziej do wewnątrz: do siebie samego. Człowiek z konieczności funkcjonuje w obu opcjach, aczkolwiek naturalnie, preferujemy najczęściej jedną z nich. Przyjęło się, że ekstrawertyk jest rozmowny a introwertyk nieśmiały. To nazbyt śmiałe i powierzchowne uproszczenie – wcale nie musie tak być. W rzeczywistości chodzi o coś więcej.
`;

export const descIntrovert = `Introwertycy skupiają swoją uwagę i energię na świecie wewnątrz samych siebie. Lubią spędzać czas w samotności i potrzebują tego, aby naładować swoje baterie. Introwertycy starają się zrozumieć świat, zanim go doświadczą. Preferują kontakty towarzyskie w niewielkich grupach i na mniejszą skalę. Introwertyk świetnie odnajdzie się w dużej grupie, jeśli tylko znajdzie innego introwertyka i będzie mógł z nim przegadać całą imprezę na interesujący ich wspólnie temat. Ekstrawertyk w tym samym czasie pozna się z każdym i przegada wiele tematów w wielu grupkach. Introwertycy z zasady unikają stawiania się w centrum uwagi bez wyraźnej potrzeby, są też zazwyczaj bardziej powściągliwi niż ekstrawertycy i wolą poznawać nowych ludzi powoli. Zanim introwertyk odpowie na pytanie, często zapada cisza, ponieważ preferuje myślenie do wewnątrz – w ciszy właśnie. Swoje pomysły, koncepcje czy idee komunikują dopiero wtedy, gdy wcześniej gruntownie je przemyślą i są one ukończone. Podobnie jest z działaniami w świecie zewnętrznym – co do zasady introwertyk, aby czuć się komfortowo podejmując działanie, potrzebuje mieć najpierw opracowany dokładny plan działania oraz najlepiej być w 100% przygotowanym. Dlatego też uzyskanie rezultatu może zająć im więcej czasu. Częściej też nie doprowadzają do końca spraw, ponieważ świadomość że nie są dostatecznie dobrze przygotowani, powoduje dyskomfort, stres i przekładanie na później „jak będę gotowy” lub „jak będzie idealnie”. Introwertycy są z zasady bardziej prywatni i prywatne informacje dzielą z kilkoma najbliższymi osobami. Introwertycy częściej mają mniej zainteresowań lecz podążają za nimi znacznie głębiej niż ekstrawertycy. Co do zasady lubią głęboko rozmyślać i analizować zjawisko, aspekt, dziedzinę – dzięki czemu rozumieją znacznie głębiej.`;

export const descExtravert = `Ekstrawertyczne typy osobowości czerpią energię z interakcji z innymi ludźmi i angażowania się w działania w ich zewnętrznym świecie. Pragną doświadczać świata i dlatego lubią dużo aktywności. "Ładują swoje baterie" poprzez przebywanie z innymi ludźmi. Lubią znajdować się w centrum akcji i będąc przystępnymi częściej poznają nowych ludzi i czynią to z łatwością. Preferują bardziej pracę z ludźmi niż w samotności. Kiedy pada pytanie ekstrawertyk zaczyna mówić bo najlepiej czuje się myśląc na głos - właściwie potrzebuje mówić, by myśleć. Ekstrawertycy najczęściej nie potrzebują mieć opracowanej odpowiedzi w 100% aby zacząć odpowiadać – często mówiąc dopracowują treść na bieżąco i potrafią być przy tym przekonujący. Podobnie z działaniem w świecie zewnętrznym. Najczęściej preferują szybkie działania kosztem przygotowania. Częściej niż introwertycy doprowadzają sprawy do końca, niemniej niekoniecznie ich rezultat w pełni oddaje potencjał. Bardziej zależy im aby coś było zrobione – wystarczy jeśli dostatecznie dobrze niż zrobione idealnie ale przez to robione dłużej bo trzeba najpierw wszystko perfekcyjnie przemyśleć i zaplanować. Wolą myśleć w biegu. Ekstrawertycy są zwykle bardziej publiczni i otwarci i swobodnie dzielą się osobistymi informacjami. Zwykle interesują się wieloma sprawami ale niekoniecznie na głębokim poziomie. Nie przepadają za głębokimi analizami.
`;

export const descMoreIntrovert = [
  "Pobudza ich spędzanie czasu w samotności",
  "Unikają stawiania się w centrum uwagi",
  "Myślą potem działają",
  "Rozważają sprawy w głębi umysłu",
  "Są bardziej prywatni. Informacje osobiste tylko w wąskim zaufanym gronie",
  "Słuchają raczej niż mówią",
  "Zachowują entuzjazm dla siebie",
  "Reagują po chwili czasu na zastanowienie",
  "Wolą głębokość niż szerokość",
];

export const descMoreExtravert = [
  "Pobudza ich przebywanie z innymi ludźmi",
  "Lubią być w centrum zainteresowania",
  "Działają potem myślą",
  "Mają tendencję do głośnego myślenia",
  "Łatwo ich rozszyfrować i poznać. Swobodnie dzielą się informacjami prywatnymi",
  "Mówią więcej niż słuchają",
  "Komunikują się entuzjastycznie",
  "Reagują szybko, lubią szybkie tempo",
  "Wolą szerokość niż głębokość",
];

export const descSensingIntuition = `POZNANIE (S) – (N) INTUICJA
Ten wymiar osobowości pokazuje **jaki rodzaj informacji** naturalnie zauważasz, spostrzegasz i odbierasz – bardziej kierując się tym co widzisz i słyszysz (plus pozostałe zmysły: węch, smak i dotyk) czy też bardziej kierując się postrzeganiem intuicyjnym czyli bardziej niż fakty interesują Cię znaczenia, relacje i możliwości wynikające z tych faktów. W codziennym życiu wszyscy używamy zarówno zmysłów jak i intuicji, mimo to większość osób wykazuje pewne preferencje wobec poznania poprzez zmysły  albo wobec intuicji, wykorzystując jeden z tych procesów bardziej naturalnie, częściej i z większym powodzeniem niż drugi. Poznający i intuicyjny, zwłaszcza jeśli te preferencje są u nich silne, widzą świat w zasadniczo odmienny sposób i często nie doceniają perspektywy drugiego.
`;
export const descSensing = `Ufa temu co da się zmierzyć, zbadać lub udokumentować i skupia się na tym co realne i konkretne. Polega na pięciu zmysłach i osobistym doświadczeniu. Jest zorientowany na teraźniejszość i skupia się na tym co się wydarza w danej chwili. Patrzy na sytuację i chce dokładnie określić co się dzieje. Poznający są szczególnie dobrzy w postrzeganiu i zapamiętywaniu wielkiej liczby faktów, lubią szczegóły i zwracają na nie uwagę. Chętniej czytają instrukcje, chętniej korzystając z dostępnych zasobów, narzędzi podchodząc do zadań. Częściej preferują pozostawienie spraw takimi jakie są, jeżeli fakty wskazują że jest ok – łatwiej doceniają i cieszą się tym , co jest tu i teraz. Osoba preferująca Poznanie, będąc na spacerze w parku, doświadczy relaksu dostrzegając i ciesząc się zapachem, uczuciem wiatru, szczegółami przyrody natomiast osoba preferująca Intuicję, również lubiąc takie spacery, zrelaksuje się samym spacerem podczas którego np. przemyśli w spokoju parę spraw, aczkolwiek niekoniecznie będzie potrafiła powiedzieć gdzie tak naprawdę była. Poznający naturalnie skupia się na realiach.`;

export const descIntuition = `Ufa swojemu szóstemu zmysłowi. W sposób naturalny czyta między wierszami i we wszystkim doszukuje się znaczeń. W przeciwieństwie do Poznających ceni wyobraźnię, ufa swoim inspiracjom i przeczuciom. Jest zorientowany na przyszłość – ludzie preferujący Intuicję mają tendencję do przewidywania wydarzeń i próbują raczej zmienić rzeczy, niż zachować je takimi jakie są. Intuicyjny przygląda się sytuacji i chce wiedzieć co ona oznacza, a także jakie mogą z niej wynikać konsekwencje. Intuicyjni lepiej interpretują fakty oraz wnikliwiej je rozumieją. Jednocześnie mało interesują się szczegółami i szukają raczej kryjących się za nimi wzorców, albo chcą zobaczyć cały obraz. Do wykonywania zadań podchodzą zupełnie inaczej niż Poznający. Na przykład zamiast używać mapy bardziej będą polegać na swoim „wyczuciu” kierunku, nie są również fanami czytania instrukcji, co może oznaczać mniejszą gotowość podążania za szczegółowymi procesami, krok po kroku a większą do działania spontanicznego lub wyrywkowego. Są bardziej ludźmi idei. Lubią opracowywać nowe, nieszablonowe rozwiązania kłopotliwych sytuacji w celu lepszej przyszłości, niemniej nie wgłębiając się w szczegóły, detale – które uważają często za nieistotne drobiazgi.`;

export const descMoreSensing = [
  "Ufają temu co pewne i konkretne",
  "Nowe pomysły są ok, jeśli mają praktyczne zastosowanie",
  "Cenią realizm i zdrowy rozsądek",
  "Lubią wykorzystywać i doskonalić posiadane umiejętności",
  "Bywają precyzyjni, ściśli i dosłowni; podają szczegółowe opisy",
  "Przedstawiają informacje krok po kroku",
  "Są zorientowani na teraźniejszość",
];

export const descMoreIntuition = [
  "Ufają inspiracji i wyciągniętym z niej wniosków",
  "Lubią nowe idee i koncepcje same w sobie",
  "Cenią wyobraźnię i innowacje",
  "Lubią opanowywać nowe umiejętności; łatwo się nudzą po nabyciu zdolności",
  "Bywają ogólni i symboliczni, obrazowi, używają metafor i analogii",
  "Przedstawiają informacje skokowo, w sposób ogólnikowy",
  "Są zorientowani na przyszłość",
];

export const descThinkingFeeling = `MYŚLENIE (T) – (F) ODCZUWANIE
Trzeci wymiar osobowości dotyczy **sposobu w jaki podejmujesz decyzje i dochodzisz do wniosków** – bardziej za pomocą myślenia czy też bardziej za pomocą odczuwania i wskazuje Twoje naturalne preferencje w tym aspekcie. W tym kontekście myślenie odnosi się do podejmowania decyzji w sposób bardziej bezstronny a odczuwanie – w oparciu o osobiste wartości. Czasami może się wydawać, że szczególnie trudno wybrać pomiędzy myśleniem a odczuwaniem. Warto jednocześnie pamiętać, że każdy wykorzystuje obie preferencje. Myślący posiadają emocje i wartości a odczuwający mogą być doskonale logiczni. Jednak większość wykorzystuje jeden z tych procesów w sposób naturalny, częściej i z większym powodzeniem niż drugi. Myślenie zazwyczaj uważa się za racjonalny sposób podejmowania decyzji, podczas gdy podejście odczuwające za irracjonalne. Tak jednak nie jest. Obie metody są racjonalne; wykorzystują po prostu różne kryteria w procesie podejmowania decyzji i mają bardzo różne priorytety.`;

export const descThinking = `Lubią decyzje, które mają logiczny sens. Szczycą się zdolnością zachowania obiektywizmu i analityczności podczas procesu podejmowania decyzji. Podejmują decyzje poprzez analizę dostępnych informacji, faktów, ważą dowody, nawet jeśli oznacza to dochodzenie do nieprzyjemnych wniosków. W pewnych okolicznościach osoby bardzo silnie preferujący myślenie kosztem odczuwania, mogą być tak silnie analityczni, że inni mogą to odbierać wręcz jako obojętność i nieczułość. W sytuacjach konfliktowych z odczuwającym mogą czuć się skonsternowani i niewiedzący o co chodzi. Zwykle jako osoby preferujące myślenie bardzo łatwo przychodzi im konstruowanie logicznych wniosków i wypowiadanie ich wprost bez owijania w bawełnę. Najczęściej są postrzegane jako osoby prawdomówne i uczciwie mówiące jak jest. Podejmując decyzje zwykle wyżej stawiają zadania niż ludzi oraz przedmiot niż relacje, stawiają również prawdomówność ponad taktem. Często doceniają wyżej ostateczny wynik i ciężką pracę niż lojalność.`;

export const descFeeling = `Podejmują decyzje w oparciu o to jak bardzo im zależy, albo o to, co czują, że jest słuszne. Są dumni ze zdolności do współczucia i empatii. W pewnych okolicznościach osoby bardzo silnie preferujące odczuwanie kosztem myślenia, mogą być tak silnie angażować, że inni mogą to odbierać wręcz jako hiper emocjonalność. W sytuacjach konfliktowych z myślących mogą czuć się zranieni i źli. Zwykle jako osoby empatyczne bardzo łatwo rozumieją dlaczego ktoś zachowuje się lub czuje się tak a nie inaczej, z czym osoby preferujące myślenie mogą mieć czasami kłopot. Najczęściej są postrzegane jako taktowne i dyplomatyczne. Podejmując decyzję zwykle wyżej stawiają ludzi niż zadania, oraz relacje wyżej niż przedmiot. Często doceniają lojalność i starania wyżej niż ostateczny wynik.`;

export const descMoreThinking = [
  "Stosują wobec problemu bezstronną analizę",
  "Cenią logikę, sprawiedliwość i uczciwość, jedna norma dla wszystkich",
  "W sposób naturalny widzą wady i bywają krytyczni",
  "Mogą być postrzegani jako niewrażliwi, bez serca i mało troskliwi",
  "Uważają, że prawdomówność jest ważniejsza niż takt",
  "Wierzą, że uczucia mają znaczenie jeśli są logiczne",
  "Motywuje ich pragnienie dokonania i osiągania",
];

export const descMoreFeeling = [
  "Rozważają wpływ działań na innych rozwiązując problem",
  "Cenią empatię i zgodę, widzą wyjątki od reguły",
  "W naturalny sposób lubią się podobać innym; łatwo okazują uznanie",
  "Mogą być postrzegani jako zbyt emocjonalni, nielogiczni i słabi",
  "Uważają, że takt jest równie ważny jak prawdomówność",
  "Wierzą, że każde uczucie ma znaczenie, niezależnie od tego czy jest rozsądne czy nie",
  "Motywuje ich pragnienie bycia docenianymi",
];

export const descJudgingPerceiving = `OSĄDZANIE (J) – (P) OBSERWACJA
Ostatni wymiar osobowości dotyczy **tego, czy preferujesz funkcjonowanie w sposób bardziej zorganizowany, czy bardziej spontaniczny** – czujesz się komfortowo, kiedy Twoje życie jest zorganizowane a sprawy ustalone czy też odczuwasz komfort kiedy masz na stole różne otwarte opcje i możliwości oraz preferujesz życie w sposób spontaniczny. W codziennym funkcjonowaniu każdy wykorzystuje zarówno preferencję do osądzania jak i do obserwacji aczkolwiek większość naturalnie preferuje częściej jedną z opcji. Dobrze jest poszukać zdrowego balansu, ponieważ osądzający narażają się na ryzyko stawania się sztywnymi, dogmatycznymi i nieelastycznymi zaś obserwujący mogą zostawiać sprawy otwartymi tak długo, jakby chcieli całe życie odłożyć na później. Wynika to z prostego faktu, że jedni chcą decydować, kontrolować i regulować swoje życie a drudzy chcą raczej je rozumieć i dostrzegać (niekoniecznie wykorzystywać) możliwości. Jednocześnie warto zdać sobie sprawę, że ludzie o preferencji osądzania niekoniecznie muszą być wyrokujący w tym sensie, że nie odstępują od swego zdania – a ludzie o preferencji obserwacji niekoniecznie muszą być spostrzegawczy we wnikliwym i dokładnym widzeniu spraw.`;
export const descJudging = `Doświadczają napięcia, dopóki sprawy nie dojdą do zakończenia i nieustannie pociąga ich podejmowanie decyzji. Wolą uporządkowany i zaplanowany świat i preferują funkcjonowanie w strukturze, która ma początek, środek i koniec. Lubią sprawować kontrolę nad tym co się dzieje. Mają tendencję do widzenia w czarno-białych barwach, kontrastach i preferują stawianie się po jednej lub drugiej stronie w sposób zdecydowany. Znacznie lepiej czują się kiedy sprawy zostają rozwiązane, niż wtedy kiedy są zawieszone – nawet jeśli to nie oni podejmują decyzję. Zwykle nie przystosowują się zbyt łatwo i zazwyczaj nie lubią niespodzianek. Preferują etykę pracy. Stojąc przed wyborem, co najpierw: praca czy zabawa, prawie zawsze wybierają pracę, a rozrywki realizują jeśli zostanie na nie czas. Niestety w przypadku bardzo silnych preferencji do osądzania, mają tendencję do przepracowywania się co niekiedy może odbijać się na ich zdrowiu. `;

export const descPerceiving = `Doświadczają napięcia, kiedy są zmuszeni do podjęcia decyzji, unikają zamykania spraw i wolą zachować otwarte opcje. Wolą świat elastyczny, z mnóstwem okazji do spontaniczności. Źle czują się w poukładanej strukturach, które nie zostawiają miejsca na kreatywność i spontan – wolą, aby sprawy toczyły się swobodnie, własnym rytmem niż ów rytm nadawać. Lubią przystosowywać się do nowych i zmieniających się sytuacji i mają tendencję do opóźniania tak dalece jak to możliwe decyzji i działań. Świat dla nich nie jest czarno biały – bardziej szary w nieskończonej ilości odcieni, bez wyraźnych kontrastów. Bardziej od „zawsze i nigdy” wolą „to zależy”. Preferują etykę zabawy. Stojąc przed wyborem, co najpierw: praca czy zabawa, częściej wybierają zabawę, a pracę dokańczają jeśli zostanie na nią czas. Często ich „kalendarz” (raczej niechętnie go prowadzą) ugina się pod natłokiem spraw pilnych i ważnych, ponieważ mając tendencję do odraczania decyzji, co może powodować narastające zaległości.`;

export const descMoreJudging = [
  "Czują się komfortowo po podjęciu decyzji",
  "Posiadają etykę pracy",
  "Ustalają cele i pracują, aby osiągnąć je w terminie",
  "Wolą wiedzieć w co się angażują",
  "Kładą nacisk na zakończenie zadania (orientacja na cel)",
  "Czerpią satysfakcję z ukończenia projektu",
  "Uznają czas za skończony, ograniczony zasób i poważnie traktują terminy końcowe",
];

export const descMorePerceiving = [
  "Czują się komfortowo pozostawiając opcje otwarte",
  "Mają etykę zabawy",
  "Zmieniają cele w miarę jak nadchodzą nowe informacje",
  "Lubią przystosowywać się do nowych sytuacji",
  "Kładą nacisk na to jak zadanie jest kończone (orientacja na drogę)",
  "Czerpią satysfakcję z rozpoczynania projektu",
  "Uważają czas za odnawialny zasób a terminy końcowe traktują elastycznie",
];
