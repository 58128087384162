import documentSetting from "./documentSettings.js";
import {
  chartGlobalGenderTypesPersonality,
  chartPersonalityTypeAndJob,
  chartTemperament,
  chartsPersonalities,
  chartsPairOfTypes,
} from "./chartsProperties.js";
import tablePreference from "./tablePreferenceProperties.js";
import "@/modules/automation/sendPdf";
import { sectionQA } from "./sectionQA/sectionQA";
import generateUserSummary from "./generateUserSummary";
import tableStrengthsWeakness from "./tableStrengthsWeakness";
import { descSummary } from "./ strengthsWeaknesses/ strengthsWeaknessesType";
import { footer } from "./pdfUtils.js";
import shortDescriptionUser from "./shortDescriptionUser.js";

const renderPDF = async (user, userTypePersonality, userType) => {
  const {
    doc,
    pageHeight,
    pageWidth,
    fontSizeNormal,
    fontSizeBold,
    fontSizeTitle,
    setFontNormal,
    setFontBold,
  } = documentSetting(user);
  await shortDescriptionUser(
    doc,
    user,
    fontSizeNormal,
    fontSizeTitle,
    userType,
    userTypePersonality,
    setFontNormal,
    setFontBold
  );
  sectionQA(doc, setFontNormal, setFontBold);
  doc.addPage();
  userType.length > 1
    ? multiRenderPersonalityTypes(
        fontSizeNormal,
        userType,
        pageWidth,
        pageHeight,
        doc,
        user,
        fontSizeBold,
        fontSizeTitle,
        userTypePersonality,
        setFontNormal,
        setFontBold
      )
    : singleRenderPersonalityType(
        fontSizeNormal,
        userType,
        pageWidth,
        pageHeight,
        doc,
        user,
        fontSizeBold,
        fontSizeTitle,
        userTypePersonality,
        setFontNormal,
        setFontBold
      );
  const namePdf = {
    filename: `${user.firstName} ${user.lastName}`,
  };
  doc.save(namePdf.filename);

  return doc.output("blob", namePdf);
};

export default renderPDF;

function singleRenderPersonalityType(
  fontSizeNormal,
  userType,
  pageWidth,
  pageHeight,
  doc,
  user,
  fontSizeBold,
  fontSizeTitle,
  userTypePersonality,
  setFontNormal,
  setFontBold
) {
  renderChartsForPersonalityType(
    fontSizeNormal,
    userType,
    pageWidth,
    pageHeight,
    doc,
    user,
    fontSizeBold,
    fontSizeTitle,
    userTypePersonality,
    setFontNormal,
    setFontBold
  );
}

function multiRenderPersonalityTypes(
  fontSizeNormal,
  userType,
  pageWidth,
  pageHeight,
  doc,
  user,
  fontSizeBold,
  fontSizeTitle,
  userTypePersonality,
  setFontNormal,
  setFontBold
) {
  const firstUserType = userType[0];
  const otherUserTypes = userType.slice(1);
  const otherUserTypesString = otherUserTypes.join(", ");
  const userResultTextPositionX = 0.5;
  const userResultTextPositionY = 0.2;
  const userResultText = {
    positionX: pageWidth * userResultTextPositionX,
    positionY: pageHeight * userResultTextPositionY,
  };
  doc.text(
    `Wyniki Twojego badania nie są jednoznaczne.\n Wskazują na wynik pośredni pomiedzy profilem \n ${firstUserType} a ${otherUserTypesString},.\n Prawdopodobnie odnajdziesz w sobie wybrane elementy obu tych profili.\n
    Zapoznaj sie uwaznie z opracowaniami zarówno dla Typu \n ${firstUserType} jak i ${otherUserTypesString}`,
    userResultText.positionX,
    userResultText.positionY,
    "center"
  );
  for (let typeIterator = 0; typeIterator < userType.length; typeIterator++) {
    renderChartsForPersonalityType(
      fontSizeNormal,
      [userType[typeIterator]],
      pageWidth,
      pageHeight,
      doc,
      user,
      fontSizeBold,
      fontSizeTitle,
      userTypePersonality,
      setFontNormal,
      setFontBold
    );
  }
}

function renderChartsForPersonalityType(
  fontSizeNormal,
  userType,
  pageWidth,
  pageHeight,
  doc,
  user,
  fontSizeBold,
  fontSizeTitle,
  userTypePersonality,
  setFontNormal,
  setFontBold
) {
  doc.setFontSize(fontSizeNormal);
  const userPersonalityTypeTextPositionX = 0.5;
  const userPersonalityTypeTextPositionY = 0.6;
  const userPersonalityTypeText = {
    positionX: pageWidth * userPersonalityTypeTextPositionX,
    positionY: pageHeight * userPersonalityTypeTextPositionY,
  };
  doc.text(
    `Typ osobowosci\n ${userType}`,
    userPersonalityTypeText.positionX,
    userPersonalityTypeText.positionY,
    "center"
  );
  chartsPairOfTypes(pageWidth, pageHeight, doc, userType);
  doc.addPage();
  doc.setFontSize(fontSizeTitle);
  setFontBold();
  const personalityProfilePositionX = 15;
  const personalityProfilePositionY = 30;
  const titlePersonalityProfile = `Profil osobowości`;
  doc.text(
    titlePersonalityProfile,
    personalityProfilePositionX,
    personalityProfilePositionY
  );
  doc.setFontSize(fontSizeNormal);
  setFontNormal();
  tablePreference(
    doc,
    userTypePersonality,
    fontSizeNormal,
    fontSizeTitle,
    pageHeight,
    pageWidth,
    setFontBold,
    setFontNormal
  );
  chartsPersonalities(pageWidth, pageHeight, doc, userType);
  doc.addPage();
  chartGlobalGenderTypesPersonality(doc, userType);
  chartPersonalityTypeAndJob(doc);
  doc.addPage();
  temperament(doc, userType, fontSizeNormal);
  doc.addPage();
  generateUserSummary(
    doc,
    pageWidth,
    fontSizeNormal,
    fontSizeBold,
    fontSizeTitle,
    user,
    userType,
    userTypePersonality,
    setFontNormal,
    setFontBold
  );
  chartTemperament(pageWidth, pageHeight, doc, userType);
  footer(doc);
}

function temperament(doc, userType, fontSizeNormal) {
  const tablePositionX = 15;
  const tablePositionY = 60;
  tableStrengthsWeakness(
    doc,
    userType,
    tablePositionX,
    tablePositionY,
    fontSizeNormal
  );
  const summaryPositionX = 15;
  const summaryPositionY = 220;
  doc.setTextColor(255, 0, 0);
  const splitSummary = doc.splitTextToSize(descSummary, 180);
  doc.text(splitSummary, summaryPositionX, summaryPositionY);
  doc.setTextColor(0, 0, 0);
}
