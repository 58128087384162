import autoTable from "jspdf-autotable";
import { allTemperamentTypes } from "./ strengthsWeaknesses/ strengthsWeaknessesType";

const tableStrengthsWeakness = (
  doc,
  userType,
  positionX,
  positionY,
  fontSizeNormal
) => {
  const temperament = findTemperamentType(userType, allTemperamentTypes);
  const tableData = [];
  if (temperament) {
    for (let i = 0; i < temperament.strengths.length; i++) {
      const { strengths, weaknesses, summary } = temperament;

      const row = [strengths[i] || "", weaknesses[i] || "", summary[i] || ""];
      tableData.push(row);
    }
  } else {
    for (let i = 0; i < 5; i++) {
      const row = ["---", "---", "---"];
      tableData.push(row);
    }
  }

  const headers = [
    [
      `Mocne strony ${userType}`,
      `Słabe strony ${userType}`,
      `Podsumowanie dla ${userType}`,
    ],
  ];

  const headStyles = {
    fillColor: "#CCCCCC",
    textColor: "#000000",
    fontStyle: "bold",
  };

  const options = {
    startY: positionX,
    startX: positionY,
    styles: {
      font: "Montserrat",
      halign: "center",
      fontSize: fontSizeNormal,
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      valign: "middle",
      align: "left",
      fillColor: "#FFFFFF",
      padding: 2,
      textColor: "#000000",
    },
    cellWidth: "wrap",
  };

  doc.autoTable({
    head: headers,
    body: tableData,
    theme: "plain",
    headStyles: headStyles,
    ...options,
  });

  return doc;
};
function findTemperamentType(userType, allTemperamentTypes) {
  const foundType = allTemperamentTypes.find((temperamentType) =>
    temperamentType.types.some((type) => type.type === userType[0])
  );

  return foundType
    ? foundType.types.find((type) => type.type === userType[0])
    : null;
}

export default tableStrengthsWeakness;
