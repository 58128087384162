<template>
  <v-form>
    <div class="ma-0 pa-1">
      <v-card variant="outlined">
        <v-row class="align-center">
          <v-col
            class="col-id"
            cols="2">
            <v-sheet class="pa-1 ma-1">
              <p class="text-center">
                <strong>{{ id }}</strong>
              </p>
            </v-sheet>
          </v-col>
          <v-divider vertical />
          <v-col class="col-title">
            <v-sheet class="pa-0 ma-0">
              <h3 class="text-start">{{ question }}</h3>
            </v-sheet>
          </v-col>
          <v-divider vertical />
          <v-col
            cols="4"
            class="col-points">
            <v-sheet class="pa-0 ma-0">
              <p class="text-center">Pkt.</p>
            </v-sheet>
          </v-col>
        </v-row>
        <v-divider color="black"></v-divider>
        <v-row>
          <v-col
            cols="2"
            class="col-question d-flex align-center justify-center">
            <v-sheet class="pa-0 ma-0">
              <p class="text-center my-auto">a)</p>
            </v-sheet>
          </v-col>
          <v-divider vertical />
          <v-col
            cols="6"
            class="col-description d-flex align-center">
            <v-sheet class="pa-0 ma-0">
              <p class="text-start">{{ descriptionA }}</p>
            </v-sheet>
          </v-col>
          <v-divider vertical />
          <v-col
            cols="4"
            class="col-select d-flex align-center justify-center">
            <v-sheet class="pa-2 ma-2">
              <v-responsive
                elevation="10"
                justify="center"
                max-width="90"
                max-height="60">
                <v-select
                  density="comfortable"
                  :bg-color="statusAnswers"
                  v-model="userA"
                  @update:modelValue="setOpositeValueFor('A'), checkAnswer()"
                  :items="['0', '1', '2', '3', '4', '5']"
                  variant="outlined"></v-select>
              </v-responsive>
            </v-sheet>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col
            cols="2"
            class="col-question d-flex align-center justify-center">
            <v-sheet class="pa-0 ma-0">
              <p class="text-center my-auto">b)</p>
            </v-sheet>
          </v-col>
          <v-divider vertical />
          <v-col
            cols="6"
            class="col-description d-flex align-center">
            <v-sheet class="pa-0 ma-0">
              <p class="text-start">{{ descriptionB }}</p>
            </v-sheet>
          </v-col>
          <v-divider vertical />
          <v-col
            cols="4"
            class="col-select d-flex align-center justify-center">
            <v-sheet class="pa-2 ma-2">
              <v-responsive
                elevation="10"
                justify="center"
                max-width="90"
                max-height="60">
                <v-select
                  density="comfortable"
                  :bg-color="statusAnswers"
                  v-model="userB"
                  @update:modelValue="setOpositeValueFor('B'), checkAnswer()"
                  :items="['0', '1', '2', '3', '4', '5']"
                  variant="outlined"></v-select>
              </v-responsive>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-form>
</template>

<script>
import { useStore } from "vuex";
import { ref } from "vue";

export default {
  name: "QuestItem",
  props: [
    "id",
    "question",
    "descriptionA",
    "descriptionB",
    "userAnswerA",
    "userAnswerB",
  ],

  setup(props) {
    const statusAnswers = ref("");
    const store = useStore();
    const userA = ref(props.userAnswerA);
    const userB = ref(props.userAnswerB);
    const maxAnswerValue = 5;

    function setOpositeValueFor(answer) {
      if (answer === 'A') {
        userB.value = maxAnswerValue - userA.value;
      }
      else {
        userA.value = maxAnswerValue - userB.value;
      }
    }
    
    function checkAnswer() {
      const data = {
        id: props.id,
        userAnswerA: userA,
        userAnswerB: userB,
      };
      const sumAnserAandB = parseInt(userA._value) + parseInt(userB._value);
      if (sumAnserAandB == maxAnswerValue) {
        this.statusAnswers = "#90ee90";
      } else if (sumAnserAandB > 0 || sumAnserAandB > maxAnswerValue) {
        const data = {
          id: props.id,
          userAnswerA: 0,
          userAnswerB: 0,
        };
        this.statusAnswers = "#ff726f";
      } else {
        const data = {
          id: props.id,
          userAnswerA: 0,
          userAnswerB: 0,
        };
        this.statusAnswers = "";
      }
      store.dispatch("questions/userAnswer", data);
    }
    return {
      userA,
      userB,
      checkAnswer,
      setOpositeValueFor,
      statusAnswers,
    };
  },
};
</script>

<style scoped>
.cols {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  h3 {
    font-size: 15px;
  }
  p {
    font-size: 14px;
  }
}
.col-question {
  flex: 2;
  max-width: 20%;
}

@media only screen and (min-width: 768px) {
  .cols {
    width: auto;
  }
  .col-question {
    align-items: center;
    justify-content: center;
  }

  .col-id,
  .col-question {
    flex: 2;
    max-width: 10%;
  }
  .col-description {
    flex: 6;
    max-width: 90%;
  }
  .col-points,
  .col-select {
    max-width: 20%;
  }
}
</style>
