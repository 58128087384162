import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const descTypes = (
  doc,
  text1,
  text2,
  positionX,
  positionY,
  columnNames1,
  columnNames2,
  icon1,
  icon2
) => {
  const data = [[text1, text2]];
  const headers = [[columnNames1, columnNames2]];

  const headStyles = {
    valign: "middle",
    halign: "center",
    fontStyle: "bold",
    cellPadding: { top: 6, bottom: 6 },
  };

  const options = {
    startY: positionY,
    startX: positionX,
    showHead: "firstPage",
    margin: { bottom: 40 },

    styles: {
      font: "Montserrat",
      halign: "left",
      fontSize: 10,
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      valign: "top",
      align: "left",
      fillColor: [255, 255, 255],
    },
    columnStyles: {
      0: { cellWidth: 90 },
      1: { cellWidth: 90 },
    },
  };
  const iconWidth = 12;
  const iconHeight = 12;
  const headImageOptions1 = {
    imageData: icon1,
    x: positionX - 8,
    y: positionY + 2,
  };
  const headImageOptions2 = {
    imageData: icon2,
    x: positionX + 82,
    y: positionY + 2,
  };

  let areImagesAdded = false;

  doc.autoTable({
    head: headers,
    body: data,
    theme: "plain",
    headStyles: headStyles,
    ...options,
    didDrawPage: (data) => {
      if (!areImagesAdded) {
        doc.addImage(
          headImageOptions1.imageData,
          "PNG",
          headImageOptions1.x,
          headImageOptions1.y,
          iconWidth,
          iconHeight
        );
        doc.addImage(
          headImageOptions2.imageData,
          "PNG",
          headImageOptions2.x,
          headImageOptions2.y,
          iconWidth,
          iconHeight
        );
        areImagesAdded = true;
      }
    },
  });

  return doc;
};

export default descTypes;
