export function introvert() {
  const introvert = [
    "2A",
    "5A",
    "9B",
    "13A",
    "17B",
    "21B",
    "26B",
    "29B",
    "33A",
    "37B",
    "43A",
    "45B",
    "49A",
    "54B",
  ];
  return introvert;
}
export function extravert() {
  const extravert = [
    "2B",
    "5B",
    "9A",
    "13B",
    "17A",
    "21A",
    "26A",
    "29A",
    "33B",
    "37A",
    "43B",
    "45A",
    "49B",
    "54A",
  ];
  return extravert;
}
export function sensing() {
  const sensing = [
    "1B",
    "6B",
    "10A",
    "14A",
    "18B",
    "22A",
    "25A",
    "30B",
    "34B",
    "38A",
    "42A",
    "47A",
    "50A",
    "51A",
  ];
  return sensing;
}
export function intuition() {
  const intuition = [
    "1A",
    "6A",
    "10B",
    "14B",
    "18A",
    "22B",
    "25B",
    "30A",
    "34A",
    "38B",
    "42B",
    "47B",
    "50B",
    "51B",
  ];
  return intuition;
}
export function thinking() {
  const thinking = [
    "3A",
    "7B",
    "11B",
    "15A",
    "19A",
    "24A",
    "27A",
    "32A",
    "35A",
    "40A",
    "41A",
    "48A",
    "52A",
    "56B",
  ];
  return thinking;
}
export function feeling() {
  const feeling = [
    "3B",
    "7A",
    "11A",
    "15B",
    "19B",
    "24B",
    "27B",
    "32B",
    "35B",
    "40B",
    "41B",
    "48B",
    "52B",
    "56A",
  ];
  return feeling;
}
export function judging() {
  const judging = [
    "4A",
    "8A",
    "12A",
    "16A",
    "20A",
    "23A",
    "28A",
    "31A",
    "36A",
    "39B",
    "44B",
    "46A",
    "53B",
    "55A",
  ];
  return judging;
}
export function perceiving() {
  const perceiving = [
    "4B",
    "8B",
    "12B",
    "16B",
    "20B",
    "23B",
    "28B",
    "31B",
    "36B",
    "39A",
    "44A",
    "46B",
    "53A",
    "55B",
  ];
  return perceiving;
}

export default {
  introvert,
  extravert,
  sensing,
  intuition,
  thinking,
  feeling,
  judging,
  perceiving,
};
