import logoTop from "../../assets/logo/logo.png";
import logoAgent from "../../assets/logo/logoAgent.png";
import store from "../../store";
import { allTemperamentTypes } from "./ strengthsWeaknesses/ strengthsWeaknessesType";
import generateUniqueId from "../pdf/generateUniqueId";
import getLastReportId from "../automation/getIDFromDB";

const shortDescriptionUser = async (
  doc,
  user,
  fontSizeNormal,
  fontSizeTitle,
  userType,
  userTypePersonality,
  setFontNormal,
  setFontBold
) => {
  displayLogoAgent(doc);
  displayLogo(doc);
  displayUserShortDescription(
    doc,
    user,
    fontSizeNormal,
    fontSizeTitle,
    userType,
    userTypePersonality,
    setFontNormal,
    setFontBold
  );
  const lastReportId = await getLastReportId();
  await generateUniqueId(doc, user, lastReportId);
};

export default shortDescriptionUser;

function displayLogo(doc) {
  const logoWidth = 35;
  const logoHeight = 15;
  const logoOptions = {
    imageData: logoTop,
    positionX: doc.internal.pageSize.width / 2 - logoWidth / 2,
    positionY: 1,
    width: logoWidth,
    height: logoHeight,
  };
  doc.addImage(
    logoOptions.imageData,
    "PNG",
    logoOptions.positionX,
    logoOptions.positionY,
    logoOptions.width,
    logoOptions.height
  );
}

function displayLogoAgent(doc) {
  const logoWidth = doc.internal.pageSize.width;
  const logoHeight = 90;
  const logoOptions = {
    imageData: logoAgent,
    positionX: doc.internal.pageSize.width / 2 - logoWidth / 2,
    positionY: doc.internal.pageSize.height / 2 - logoHeight / 2,
    width: logoWidth,
    height: logoHeight,
  };
  doc.addImage(
    logoOptions.imageData,
    "PNG",
    logoOptions.positionX,
    logoOptions.positionY,
    logoOptions.width,
    logoOptions.height
  );
}

function displayUserShortDescription(
  doc,
  user,
  fontSizeNormal,
  fontSizeTitle,
  userType,
  userTypePersonality,
  setFontNormal,
  setFontBold
) {
  doc.setFontSize(fontSizeTitle);
  setFontBold();
  const textPositionX = 15;
  const textPositionY = 70;
  const shortDescAboutUser = `${user.firstName} w skrócie...`;
  doc.text(shortDescAboutUser, textPositionX, textPositionY);
  doc.setFontSize(fontSizeNormal);
  setFontNormal();
  const personalityData = store.getters["questions/personalityData"];
  const userJob = findJobForPersonalityType(personalityData, userType);
  const userPersonality = findUserPersonality(userTypePersonality);
  const userTemperament = findTemperamentType(userType, allTemperamentTypes);
  const userSummary = `${userType}, ${userTemperament}, ${userJob}, ${userPersonality}`;
  const splitUserSummary = doc.splitTextToSize(userSummary, 180);
  const userJobByPersonalityTypePositionX = 15;
  const userJobByPersonalityTypePositionY = 80;
  doc.text(
    splitUserSummary,
    userJobByPersonalityTypePositionX,
    userJobByPersonalityTypePositionY
  );
}

function findJobForPersonalityType(personalityData, userType) {
  for (const personalityGroup in personalityData) {
    const personalities = personalityData[personalityGroup];
    for (const personality of personalities) {
      if (personality.type === userType[0]) {
        return personality.job;
      }
    }
  }
  return "Unknown"; // Zwracane, jeśli nie odnaleziono typu osobowości
}

function findUserPersonality(userTypePersonality) {
  const {
    resultExtravert,
    resultIntrovert,
    resultSensig,
    resultIntuition,
    resultThinking,
    resultFeeling,
    resultJudging,
    resultPerceiving,
  } = userTypePersonality;

  const extravert = resultExtravert > resultIntrovert ? [" Ekstrawertyk"] : [];
  const introvert = resultExtravert < resultIntrovert ? [" Introwertyk"] : [];
  const extravertIntrovert =
    resultExtravert === resultIntrovert
      ? [" Ekstrawertyk", " Introwertyk"]
      : [];

  const sensing = resultSensig > resultIntuition ? [" Poznawczy"] : [];
  const intuition = resultSensig < resultIntuition ? [" Intuicyjny"] : [];
  const sensingIntuition =
    resultSensig === resultIntuition ? [" Poznawczy", " Intuicyjny"] : [];

  const thinking = resultThinking > resultFeeling ? [" Myślący"] : [];
  const feeling = resultThinking < resultFeeling ? [" Odczuwający"] : [];
  const thinkingFeeling =
    resultThinking === resultFeeling ? [" Myślący", " Odczuwający"] : [];

  const judging = resultJudging > resultPerceiving ? [" Osądzający"] : [];
  const perceiving = resultJudging < resultPerceiving ? [" Obserwacyjny"] : [];
  const judgingPerceiving =
    resultJudging === resultPerceiving ? [" Osądzający", " Obserwacyjny"] : [];

  return [
    ...extravert,
    ...introvert,
    ...extravertIntrovert,
    ...sensing,
    ...intuition,
    ...sensingIntuition,
    ...thinking,
    ...feeling,
    ...thinkingFeeling,
    ...judging,
    ...perceiving,
    ...judgingPerceiving,
  ];
}

function findTemperamentType(userType, allTemperamentTypes) {
  for (const temperamentType of allTemperamentTypes) {
    for (const type of temperamentType.types) {
      if (type.type === userType[0]) {
        return temperamentType.personalityTypes;
      }
    }
  }
  return null;
}
