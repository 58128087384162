import autoTable from "jspdf-autotable";

const tablePreference = (doc, userTypePersonality, fontSizeNormal) => {
  let columns = [
    [
      {
        content: "Typ osobowości",
        colSpan: 6,
        styles: { halign: "center" },
      },
    ],
  ];

  var data = [
    [
      "EKSTRAWERSJA(EXTRAVERT)",
      "E",
      userTypePersonality.resultExtravert,
      userTypePersonality.resultIntrovert,
      "I",
      "INTROWERSJA(INTROVERT)",
    ],
    [
      "POZNANIE(SENSING)",
      "S",
      userTypePersonality.resultSensig,
      userTypePersonality.resultIntuition,
      "N",
      "INTUICJA(INTUITION)",
    ],
    [
      "MYŚLENIE(THINKING)",
      "T",
      userTypePersonality.resultThinking,
      userTypePersonality.resultFeeling,
      "F",
      "ODCZUWANIE(FEELING)",
    ],
    [
      "OSĄDZANIE(JUDGING)",
      "J",
      userTypePersonality.resultJudging,
      userTypePersonality.resultPerceiving,
      "P",
      "OBSERWACJA(PERCEIVING)",
    ],
  ];
  doc.autoTable({
    theme: "grid",
    tableWidth: 180,
    cellWidth: "auto",
    margin: { top: 70 },
    tableLineWidth: 0,
    headStyles: {
      lineWidth: 0.5,
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      fontSize: fontSizeNormal,
      padding: 0,
    },
    styles: {
      font: "Montserrat",
      halign: "center",
      fontSize: fontSizeNormal,
      cellPadding: 2,
      minCellHeight: 2,
    },
    cellWidth: "wrap",
    head: columns,
    body: data,
    didParseCell: highlightCellsTwoToFour,
  });
  return doc;
};

function highlightCellsTwoToFour(data) {
  const lineColor = [255, 130, 130];
  const blackColor = [0, 0, 0];
  const fontSize = 14;

  if (data.column.index === 2) {
    const { cells } = data.row;
    const cell2 = cells[2];
    const cell3 = cells[3];

    if (cell2.raw === cell3.raw) {
      [1, 2, 3, 4].forEach((index) => {
        cells[index].styles.fillColor = lineColor;
        cells[index].styles.textColor = blackColor;
        cells[index].styles.fontSize = fontSize;
        cells[index].styles.fontWeight = "bold";
      });
    } else if (cell2.raw > cell3.raw) {
      [1, 2].forEach((index) => {
        cells[index].styles.fillColor = lineColor;
        cells[index].styles.fontWeight = "bold";
        cells[index].styles.textColor = blackColor;
        cells[index].styles.fontSize = fontSize;
      });
    } else {
      [3, 4].forEach((index) => {
        cells[index].styles.fillColor = lineColor;
        cells[index].styles.fontWeight = "bold";
        cells[index].styles.textColor = blackColor;
        cells[index].styles.fontSize = fontSize;
      });
    }
  }
}

export default tablePreference;
