import jsPDF from "jspdf";
import "../../fonts/Montserrat-normal";
import "../../fonts/Montserrat-bold";

const documentSetting = (user) => {
  const doc = new jsPDF("p", "mm", "a4", true);
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const fontSizeNormal = 12;
  const fontSizeBold = 14;
  const fontSizeTitle = 30;
  const setFontBold = () => {
    doc.setFont("Montserrat", "bold");
  };

  const setFontNormal = () => {
    doc.setFont("Montserrat", "normal");
  };

  doc.setFontSize(fontSizeNormal);
  doc.setProperties({
    title: `${user.firstName} ${user.lastName}`,
  });
  return {
    doc: doc,
    pageHeight: pageHeight,
    pageWidth: pageWidth,
    fontSizeNormal: fontSizeNormal,
    fontSizeBold: fontSizeBold,
    fontSizeTitle: fontSizeTitle,
    setFontNormal: setFontNormal,
    setFontBold: setFontBold,
  };
};

export default documentSetting;
