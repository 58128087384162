export default {
  questions(state) {
    return state.questions;
  },
  totalPoints(state) {
    return state.totalPoints;
  },
  userPoints(state) {
    let questions = state.questions;
    let userSum = 0;
    for (let i = 0; i < questions.length; i++) {
      userSum += questions[i].userAnswerA;
      userSum += questions[i].userAnswerB;
    }
    return userSum;
  },
  checkUserPoints(state, getters) {
    const userPoints = getters.userPoints;
    const getTotalPoints = state.totalPoints;
    return userPoints === getTotalPoints;
  },
  personalityData(state) {
    return state.personalityData;
  },
};
