export function formatTextWithBoldStyles(doc, text, startX, startY) {
  const lineSpacing = 6;
  const textItemSpacing = 4.3;
  const splitText = doc.splitTextToSize(text, 180);
  let isBold = false;

  splitText.forEach((text) => {
    const arrayOfNormalAndBoldText = text.split("**");

    arrayOfNormalAndBoldText.forEach((textItem, index) => {
      const font = isBold ? "Montserrat" : "Montserrat";
      const fontStyle = isBold ? "bold" : "normal";

      doc.setFont(font, fontStyle);
      doc.text(textItem, startX, startY);

      startX += doc.getStringUnitWidth(textItem) * textItemSpacing;

      if (index < arrayOfNormalAndBoldText.length - 1) {
        isBold = !isBold;
      }
    });
    startX = 15;
    startY += lineSpacing;
  });
}
