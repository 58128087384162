const sumPreference = (userAnswers, numberQuestion) => {
  var sumPoints = 0;
  for (let i = 0; i < numberQuestion.length; i++) {
    const lastLetter = numberQuestion[i].slice(-1);
    const index = parseInt(numberQuestion[i]) - 1;
    const matchingQuestion = userAnswers.find((q) => q.id === index + 1);
    if (matchingQuestion) {
      if (lastLetter === "A") {
        const userValueA = matchingQuestion.userAnswerA;
        sumPoints = sumPoints + userValueA;
      } else if (lastLetter === "B") {
        const userValueB = matchingQuestion.userAnswerB;
        sumPoints = sumPoints + userValueB;
      }
    }
  }
  return sumPoints;
};
export default sumPreference;
