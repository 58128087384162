import axios from "axios";
import { notify, useNotification } from "@kyvg/vue3-notification";

const sendPDFviaWebhook = (pdf, fileName, userTypePersonality) => {
  const url = "https://hook.eu1.make.com/2brokfcpfpf3gfdlftcf7dqkbupftr5o";
  axios
    .post(
      url,
      {
        fileName: fileName,
        userPoints: userTypePersonality,
        pdf: pdf,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => {
      notify({
        text: "Kwestionariusz został wysłany!",
        type: "success",
        duration: 10000,
      });
    })
    .catch((error) => {
      notify({
        text: "Wystąpił bląd podczas wysłania kwestionariusza",
        type: "error",
        duration: 10000,
      });
    });
};

export default sendPDFviaWebhook;
