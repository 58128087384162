import {
  quesiton1,
  question2,
  question3,
  question4,
  question5,
} from "./questionQA";
import { answer1, answer2, answer3, answer4, answer5 } from "./answerQA";

export const sectionQA = (doc, setFontNormal, setFontBold) => {
  const fontSizeNormal = 9;
  const fontSizeBold = 11;
  const fontSizeTitle = 30;
  doc.addPage();
  const titleQAPositionX = 15;
  const titleQAPositionY = 20;
  setFontBold();
  doc.setFontSize(fontSizeTitle);
  doc.text("Q&A", titleQAPositionX, titleQAPositionY);

  const question1PositionX = 15;
  const question1PositionY = 30;
  setFontBold();
  doc.setFontSize(fontSizeBold);
  doc.text(quesiton1, question1PositionX, question1PositionY);
  const answer1PositionX = 15;
  const answer1PositionY = 35;
  const splitTextAnswer1 = doc.splitTextToSize(answer1, 220);
  setFontNormal();
  doc.setFontSize(fontSizeNormal);
  doc.text(splitTextAnswer1, answer1PositionX, answer1PositionY);

  const question2PositionX = 15;
  const question2PositionY = 52;
  const splitTextQuestion2 = doc.splitTextToSize(question2, 135);
  setFontBold();
  doc.setFontSize(fontSizeBold);
  doc.text(splitTextQuestion2, question2PositionX, question2PositionY);
  const answer2PositionX = 15;
  const answer2PositionY = 62;
  const splitTextAnswer2 = doc.splitTextToSize(answer2, 220);
  setFontNormal();
  doc.setFontSize(fontSizeNormal);
  doc.text(splitTextAnswer2, answer2PositionX, answer2PositionY);

  const question3PositionX = 15;
  const question3PositionY = 137;
  const splitTextQuestion3 = doc.splitTextToSize(question3, 200);
  setFontBold();
  doc.setFontSize(fontSizeBold);
  doc.text(splitTextQuestion3, question3PositionX, question3PositionY);
  const answer3PositionX = 15;
  const answer3PositionY = 142;
  const splitTextAnswer3 = doc.splitTextToSize(answer3, 220);
  setFontNormal();
  doc.setFontSize(fontSizeNormal);
  doc.text(splitTextAnswer3, answer3PositionX, answer3PositionY);

  const question4PositionX = 15;
  const question4PositionY = 182;
  const splitTextQuestion4 = doc.splitTextToSize(question4, 220);
  setFontBold();
  doc.setFontSize(fontSizeBold);
  doc.text(splitTextQuestion4, question4PositionX, question4PositionY);

  const answer4PositionX = 15;
  const answer4PositionY = 187;
  const splitTextAnswer4 = doc.splitTextToSize(answer4, 220);
  setFontNormal();
  doc.setFontSize(fontSizeNormal);
  doc.text(splitTextAnswer4, answer4PositionX, answer4PositionY);

  const question5PositionX = 15;
  const question5PositionY = 205;
  const splitTextQuestion5 = doc.splitTextToSize(question5, 220);
  setFontBold();
  doc.setFontSize(fontSizeBold);
  doc.text(splitTextQuestion5, question5PositionX, question5PositionY);
  const answer5PositionX = 15;
  const answer5PositionY = 210;
  const splitTextAnswer5 = doc.splitTextToSize(answer5, 220);
  setFontNormal();
  doc.setFontSize(fontSizeNormal);
  doc.text(splitTextAnswer5, answer5PositionX, answer5PositionY);
};
