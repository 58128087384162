const getUserPersonalityType = (userTypePersonality) => {
  const allPersonalityTypeList = [
    "ISTP",
    "ISFP",
    "ESTP",
    "ESFP",
    "ISTJ",
    "ISFJ",
    "ESTJ",
    "ESFJ",
    "INFJ",
    "INFP",
    "ENFJ",
    "ENFP",
    "INTJ",
    "INTP",
    "ENTJ",
    "ENTP",
  ];
  const possibleTypes = [];
  if (
    !userTypePersonality ||
    typeof userTypePersonality !== "object" ||
    Object.keys(userTypePersonality).length !== 8
  ) {
    throw new TypeError("Invalid input: expected an object with 8 properties");
  }
  userTypePersonality.resultExtravert === userTypePersonality.resultIntrovert ||
  userTypePersonality.resultSensig === userTypePersonality.resultIntuition ||
  userTypePersonality.resultThinking === userTypePersonality.resultFeeling ||
  userTypePersonality.resultJudging === userTypePersonality.resultPerceiving
    ? multiPersonalityTypes(
        userTypePersonality,
        allPersonalityTypeList,
        possibleTypes
      )
    : singlePersonalityTypes(userTypePersonality, possibleTypes);
  return possibleTypes;
};

function singlePersonalityTypes(userTypePersonality, possibleTypes) {
  const userType =
    "" +
    (userTypePersonality.resultExtravert > userTypePersonality.resultIntrovert
      ? "E"
      : "I") +
    (userTypePersonality.resultSensig > userTypePersonality.resultIntuition
      ? "S"
      : "N") +
    (userTypePersonality.resultThinking > userTypePersonality.resultFeeling
      ? "T"
      : "F") +
    (userTypePersonality.resultJudging > userTypePersonality.resultPerceiving
      ? "J"
      : "P");
  possibleTypes.push(userType);
}

function multiPersonalityTypes(
  userTypePersonality,
  allPersonalityTypeList,
  possibleTypes
) {
  const personalityOptions = [
    ["Extravert", "Introvert", "E", "I"],
    ["Sensig", "Intuition", "S", "N"],
    ["Thinking", "Feeling", "T", "F"],
    ["Judging", "Perceiving", "J", "P"],
  ];

  for (
    let indexPersonalityOptions = 0;
    indexPersonalityOptions < personalityOptions.length;
    indexPersonalityOptions++
  ) {
    const currentOptions = personalityOptions[indexPersonalityOptions];
    const dimensionResult1 = userTypePersonality["result" + currentOptions[0]];
    const dimensionResult2 = userTypePersonality["result" + currentOptions[1]];

    if (dimensionResult1 === dimensionResult2) {
      const possibleLetters = [currentOptions[2], currentOptions[3]];
      allPersonalityTypeList = filterDrawPersonalityTypes(
        allPersonalityTypeList,
        indexPersonalityOptions,
        possibleLetters
      );
    } else {
      dimensionResult1 > dimensionResult2
        ? (allPersonalityTypeList = filterPersonalityTypesByLetter(
            allPersonalityTypeList,
            indexPersonalityOptions,
            currentOptions[2]
          ))
        : (allPersonalityTypeList = filterPersonalityTypesByLetter(
            allPersonalityTypeList,
            indexPersonalityOptions,
            currentOptions[3]
          ));
    }
  }
  possibleTypes.splice(0, possibleTypes.length, ...allPersonalityTypeList);
}

function filterPersonalityTypesByLetter(
  allPersonalityTypeList,
  letterIndex,
  letter
) {
  const filteredTypes = allPersonalityTypeList.filter(
    (type) => type[letterIndex] === letter
  );
  return filteredTypes;
}

function filterDrawPersonalityTypes(
  allPersonalityTypeList,
  indexPersonalityOptions,
  possibleLetters
) {
  const filteredTypes = allPersonalityTypeList.filter((type) =>
    possibleLetters.includes(type[indexPersonalityOptions])
  );
  return filteredTypes;
}

export default getUserPersonalityType;
