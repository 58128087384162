import { createRouter, createWebHistory } from "vue-router";

import QuestionList from "./pages/QuestionList.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", redirect: "/questions" },
    { path: "/questions", component: QuestionList },
  ],
});

export default router;
