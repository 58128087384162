<template>
  <v-app>
    <v-container>
      <v-row>
        <v-col>
          <v-card class="text-center">
            <h1>Kwestionariusz MBTI</h1>
            <h2 class="pa-md-2 mx-auto">INFORMACJE O UCZESTNIKU BADANIA</h2>
            <form
              justify-center
              fast-fail
              novalidate
              @submit.prevent>
              <div>
                <p class="text-center pa-md-2">
                  1. Twoje imię i nazwisko wyniki
                  <strong
                    >zostaną zanonimizowane. W zbiorach danych w Dziale Analiz
                    IMDM, Twój rekord będzie oznaczony symbolem.</strong
                  >
                </p>
                <v-row
                  class="pa-md-1 d-flex align-items-center justify-content-center"
                  justify="center">
                  <v-col cols="10">
                    <v-radio-group
                      class="d-flex align-center justify-center"
                      v-model="user.gender"
                      :error-messages="v$.gender.$errors.map((e) => e.$message)"
                      inline
                      required>
                      <v-radio
                        label="Kobieta"
                        value="female"
                        color="success"
                        class="mr-2"
                        required />
                      <v-radio
                        class="ml-2"
                        label="Mężczyzna"
                        value="male"
                        color="success"
                        required />
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row
                  class="pa-mt-5"
                  justify="center">
                  <v-col
                    cols="10"
                    md="4">
                    <div class="form-control">
                      <v-text-field
                        :error-messages="
                          v$.firstName.$errors.map((e) => e.$message)
                        "
                        v-model="formattedFirstName"
                        label="Imię"
                        persistent-hint
                        required
                        @input="v$.firstName.$touch"
                        @blur="v$.firstName.$touch"
                        variant="outlined"></v-text-field>
                    </div>
                  </v-col>
                  <v-col
                    cols="10"
                    md="4">
                    <v-text-field
                      required
                      clearable
                      :error-messages="
                        v$.lastName.$errors.map((e) => e.$message)
                      "
                      @input="v$.lastName.$touch"
                      @blur="v$.lastName.$touch"
                      v-model="formattedLastName"
                      persistent-hint
                      label="Nazwisko"
                      variant="outlined"></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  class="pa-mt-5"
                  justify="center">
                  <v-col
                    cols="10"
                    md="4">
                    <div class="form-control">
                      <v-text-field
                        :error-messages="
                          v$.email.$errors.map((e) => e.$message)
                        "
                        v-model="user.email"
                        label="Email"
                        persistent-hint
                        required
                        @input="v$.email.$touch"
                        @blur="v$.email.$touch"
                        variant="outlined"></v-text-field>
                    </div>
                  </v-col>
                  <v-col
                    cols="10"
                    md="4">
                    <v-text-field
                      required
                      clearable
                      :error-messages="
                        v$.postalCode.$errors.map((e) => e.$message)
                      "
                      @input="v$.postalCode.$touch"
                      @blur="v$.postalCode.$touch"
                      v-model="user.postalCode"
                      label="Kod Pocztowy"
                      variant="outlined"></v-text-field>
                  </v-col>
                </v-row>
                <p class="text-center mb-5 mt-5">
                  2.Pozostałe informacje(wypełnij jeżeli nie podawałeś wcześniej
                  w innym badaniu dla IMDM).
                </p>
                <v-row
                  class="pa-md-5"
                  justify="center">
                  <v-col
                    cols="10"
                    md="4">
                    <v-text-field
                      :error-messages="v$.age.$errors.map((e) => e.$message)"
                      @input="v$.age.$touch"
                      @blur="v$.age.$touch"
                      v-model.number="user.age"
                      onkeypress="return event.charCode >= 48"
                      required
                      @change="
                        () => {
                          if (user.age > 100 || user.age < 0) {
                            this.user.age = 1;
                          }
                        }
                      "
                      type="number"
                      label="Wiek"
                      persistent-hint
                      variant="outlined"></v-text-field>
                  </v-col>
                  <v-col
                    cols="10"
                    md="4">
                    <v-text-field
                      required
                      :error-messages="v$.city.$errors.map((e) => e.$message)"
                      @input="v$.city.$touch"
                      @blur="v$.city.$touch"
                      v-model="formattedCity"
                      label="Miasto"
                      persistent-hint
                      variant="outlined"></v-text-field>
                  </v-col>
                </v-row>
                <div class="pa-md-2 mx-auto text-center">
                  <h2>KWESTIONARIUSZ BADANIA MBTI</h2>
                  <h3>(MAYERS – BRIGSS TYPE INDICATOR)</h3>
                  <div class="text-center pa-md-4 mx-lg-auto">
                    <h2>INSTRUKCJA DLA UCZESTNIKA BADANIA:</h2>
                    <ol class="p-10 ma-10 text-start">
                      <li>
                        Zapoznaj się ze stwierdzeniem - każde ma dwie wersje (a
                        i b)
                      </li>
                      <li>Przeczytaj uważnie każdą z wersji.</li>
                      <li>
                        Pomiędzy stwierdzenia a i b
                        <strong><u>rozdziel</u></strong> 5 punktów w zależności
                        od tego, z którym z nich bardziej się zgadzasz. Zastanów
                        się i przemyśl, kierując się faktami ze swojej
                        rzeczywistości.
                      </li>
                      <li>
                        Suma punktów przy stwierdzeniach a i b zawsze musi
                        wynosić 5.
                      </li>
                      <li>
                        Przydzielaj pełne punkty co oznacza, że nie możesz
                        przyznać np.: 2,5 pkt którejś z wersji stwierdzenia.
                      </li>
                      <li>
                        Możliwe permutacje punktów: 5-0; 4-1; 3-2; 2-3; 1-4 lub 0-5
                      </li>
                      <li>Przykładowe oznaczenia:</li>
                      <p>
                        5-0 (całkowicie zgadzam się z „a” w ogóle nie zgadzam się
                        z „b”)
                      </p>
                      <p>
                        4-1 (zdecydowanie zgadzam się z „a” minimalnie zgadzam
                        się z „b”)
                      </p>
                      <p>
                        3-2 (raczej zgadzam się z „a” trochę mniej zgadzam się z
                        „b”)
                      </p>
                    </ol>
                  </div>
                  <h3>START</h3>
                </div>
              </div>
              <quest-item
                v-for="quest in loadQuests"
                :key="quest.id"
                :id="quest.id"
                :question="quest.question"
                :descriptionA="quest.descriptionA"
                :descriptionB="quest.descriptionB"
                :userAnswerA="quest.userAnswerA"
                :userAnswerB="quest.userAnswerB"></quest-item>
              <v-btn
                type="submit"
                color="success"
                block
                @click="submitForm"
                >Zapisz & zakończ badanie</v-btn
              >
            </form>
          </v-card>
        </v-col>
      </v-row>
      <div class="charts">
        <div id="chart-container"></div>
        <div id="chart-container-personality-job"></div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import { ref, reactive, computed, watch } from "vue";
import { useStore } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import QuestItem from "../components/questions/QuestItem.vue";
import { useNotification } from "@kyvg/vue3-notification";
import getCalculateUserPoints from "../modules/user/calculateUserPoints";
import getUserPersonalityType from "../modules/user/userPersonalityType";
import rendersCharts from "../modules/charts/personalityTypeVisualizations.js";
import renderPDF from "../modules/pdf/renderPDF";
import sendPDFviaWebhook from "@/modules/automation/sendPdf";
import getIDfromDB from "../modules/automation/getIDFromDB";

export default {
  name: "QuestList",
  components: {
    QuestItem,
  },
  setup() {
    const store = useStore();
    const user = reactive({
      firstName: "",
      lastName: "",
      gender: "",
      age: "",
      city: "",
      email: "",
      postalCode: "",
    });

    const { notify } = useNotification();

    const createFormattedPropertyComputed = (propertyName) => {
      return computed({
        get() {
          return user[propertyName];
        },
        set(value) {
          const formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
          user[propertyName] = formattedValue.trim();
        },
      });
    };

    const formattedFirstName = createFormattedPropertyComputed("firstName");
    const formattedLastName = createFormattedPropertyComputed("lastName");
    const formattedCity = createFormattedPropertyComputed("city");

    const polishCharactersRegex = /^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ ]*$/;
    ["firstName", "lastName", "city"].forEach((field) => {
      watch(
        () => user[field],
        (newVal, oldVal) => {
          if (!newVal.match(polishCharactersRegex)) {
            user[field] = newVal.replace(/[^A-Za-z]/g, "");
          }
        }
      );
    });

    ["postalCode"].forEach((field) => {
      watch(
        () => user[field],
        (newVal, oldVal) => {
          const numericValue = newVal.replace(/[^\d]/g, "");

          if (numericValue.length > 5) {
            user[field] = numericValue.slice(0, 5);
          } else {
            user[field] = numericValue;
          }

          if (numericValue.length >= 3 && numericValue.length <= 5) {
            user[field] =
              numericValue.slice(0, 2) + "-" + numericValue.slice(2);
          }
        }
      );
    });

    const fields = [
      "firstName",
      "lastName",
      "age",
      "city",
      "gender",
      "email",
      "postalCode",
    ];
    const requiredRule = helpers.withMessage(
      "To pole nie może być puste",
      required
    );
    const emailRule = helpers.withMessage(
      "Nieprawidłowy adres e-mail",
      (value) => value.includes("@")
    );

    const postalCodeRule = helpers.withMessage(
      "Kod pocztowy musi składać się z dokładnie 5 cyfr",
      (value) => /^\d{2}-\d{3}$/.test(value)
    );

    const rules = fields.reduce((rulesObject, field) => {
      rulesObject[field] = { required: requiredRule };
      if (field === "email") {
        rulesObject[field].email = emailRule;
      }
      if (field === "postalCode") {
        rulesObject[field].postalCode = postalCodeRule;
      }
      return rulesObject;
    }, {});

    const v$ = useVuelidate(rules, user);

    const loadQuests = computed(() => {
      return store.getters["questions/questions"];
    });

    function allPointsFilled() {
      return store.getters["questions/checkUserPoints"];
    }

    const submitForm = async () => {
      const result = await v$.value.$validate();
      if (result && allPointsFilled()) {
        generatePDF();
        notify({
          text: "Dziękujemy za uzupełnienie kwestionariusza",
          type: "success",
          duration: 10000,
        });
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        notify({
          text: "Nie są wszystkie odpowiedzi zaznaczone",
          type: "error",
          duration: 10000,
        });
      }
    };

    async function generatePDF() {
      getIDfromDB();
      const userTypePersonality = getCalculateUserPoints();
      const userType = getUserPersonalityType(userTypePersonality);
      for (const type of userType) {
        await rendersCharts(userTypePersonality, type, user);
      }
      const pdf = await renderPDF(user, userTypePersonality, userType);
      if (process.env.VUE_APP_SEND_WEBHOOK === "true") {
        sendPDFviaWebhook(pdf, user, userTypePersonality);
      }
    }

    const formattedPostalCode = computed(() => {
      const numericPostalCode = user.postalCode.replace(/[^\d]/g, "");
      const formatted = `${numericPostalCode.slice(
        0,
        2
      )}-${numericPostalCode.slice(2)}`;
      return formatted;
    });
    return {
      user,
      v$,
      loadQuests,
      formattedFirstName,
      formattedLastName,
      formattedCity,
      submitForm,
      formattedPostalCode,
    };
  },
};
</script>
<style>
#chart-container {
  position: relative;
  height: 200px;
  width: 400px;
}
#chart-container-personality-job {
  position: relative;
  height: 500px;
  width: 800px;
}
.charts {
  visibility: hidden;
}
</style>
