import jsPDF from "jspdf";

const generateUniqueId = async (doc, user, lastReportId) => {
  try {
    const sexCode = user.gender.toLowerCase() === "male" ? "M" : "K";
    const ageCode = user.age;
    const postalCode = user.postalCode.replace("-", "");
    const hardCodeNumber = 3;
    const currentYear = new Date().getFullYear().toString().slice(-2);
    const randomChars = await generateRandomCharacters(5);
    const currentReportId = lastReportId.toString();
    const uniqueCode = `${sexCode}${ageCode}/${postalCode}/${randomChars}${hardCodeNumber}${currentReportId}/${currentYear}`;
    const textPositionX = doc.internal.pageSize.getWidth() / 2;
    const textPositionY = 250;
    doc.text(uniqueCode, textPositionX, textPositionY, { align: "center" });
  } catch (error) {
    console.error("Błąd podczas generowania unikalnego ID:", error);
  }
};

function generateRandomCharacters(length) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let randomChars = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomChars += characters.charAt(randomIndex);
  }
  return randomChars;
}

export default generateUniqueId;
