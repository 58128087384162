import { createStore } from "vuex";
import questionsModule from "./modules/imdm/index.js";

const store = createStore({
  modules: {
    questions: questionsModule,
  },
  state: {},
  getters: {},
  mutations: {},
  actions: {},
});

export default store;
