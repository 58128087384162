import sumPreference from "./sumPreference";
import {
  introvert,
  extravert,
  sensing,
  judging,
  perceiving,
  feeling,
  intuition,
  thinking,
} from "../../modules/user/keyAnswerPersonalityType";
import store from "../../store";

const userAnswers = store.getters["questions/questions"];
const getCalculateUserPoints = () => {
  const resultIntrovert = sumPreference(userAnswers, introvert());
  const resultExtravert = sumPreference(userAnswers, extravert());
  const resultSensig = sumPreference(userAnswers, sensing());
  const resultIntuition = sumPreference(userAnswers, intuition());
  const resultThinking = sumPreference(userAnswers, thinking());
  const resultFeeling = sumPreference(userAnswers, feeling());
  const resultJudging = sumPreference(userAnswers, judging());
  const resultPerceiving = sumPreference(userAnswers, perceiving());
  const data = {
    resultIntrovert: resultIntrovert,
    resultExtravert: resultExtravert,
    resultSensig: resultSensig,
    resultIntuition: resultIntuition,
    resultThinking: resultThinking,
    resultFeeling: resultFeeling,
    resultJudging: resultJudging,
    resultPerceiving: resultPerceiving,
  };
  return data;
};

export default getCalculateUserPoints;
