import getElementChart from "./getElementChart.js";
import {
  descriptionExtravertIntrovertPosition,
  descriptionSensingIntuitionPosition,
  descriptionThinkingFeelingPosition,
  descriptionJudgingPerceivingPosition,
} from "../pdf/descriptionPosition.js";

export function chartsPersonalities(pageWidth, pageHeight, doc, userType) {
  positionChartExtravertSensingThinkingJudging(
    pageWidth,
    pageHeight,
    doc,
    userType
  );
  positionChartIntrovertIntuitionFeelingPerceiving(
    pageWidth,
    pageHeight,
    doc,
    userType
  );
}

export function chartsPairOfTypes(pageWidth, pageHeight, doc, userType) {
  positionChartExtravertIntrovert(pageWidth, pageHeight, doc, userType);
  positionChartSensingIntuition(pageWidth, pageHeight, doc, userType);
  positionChartThinkingFeeling(pageWidth, pageHeight, doc, userType);
  positionChartJudgingPerceiving(pageWidth, pageHeight, doc, userType);
}

export function chartGlobalGenderTypesPersonality(doc, userType) {
  const imageFormat = "PNG";
  const chartPositionX = 30;
  const chartPositionY = 0;
  const chartSize = {
    width: 150,
    height: 150,
  };
  const getGlobalGenderTypesPersonality = getElementChart(
    `chartGlobalGenderTypesPersonality ${userType}`,
    imageFormat
  );

  doc.addImage(
    getGlobalGenderTypesPersonality,
    imageFormat,
    chartPositionX,
    chartPositionY,
    chartSize.width,
    chartSize.height,
    undefined,
    "FAST"
  );
}

export function chartPersonalityTypeAndJob(doc) {
  const imageFormat = "PNG";
  const chartPositionX = 15;
  const chartPositionY = 150;
  const chartSize = {
    width: 180,
    height: 100,
  };
  const getChartPersonalityTypeAndJob = getElementChart(
    "PersonalityTypeJob",
    imageFormat
  );
  doc.addImage(
    getChartPersonalityTypeAndJob,
    imageFormat,
    chartPositionX,
    chartPositionY,
    chartSize.width,
    chartSize.height,
    undefined,
    "FAST"
  );
}

export function chartTemperament(pageWidth, pageHeight, doc, userType) {
  const imageFormat = "PNG";
  const chartPositionX = 15;
  const chartPositionY = 150;
  const chartPosition = {
    positionX: chartPositionX,
    positionY: chartPositionY,
  };
  const chartSize = {
    width: 180,
    height: 100,
  };
  const getChartTemperament = getElementChart(
    `Temperament ${userType}`,
    imageFormat
  );
  doc.addImage(
    getChartTemperament,
    imageFormat,
    chartPosition.positionX,
    chartPosition.positionY,
    chartSize.width,
    chartSize.height,
    undefined,
    "FAST"
  );
}

function positionChartExtravertIntrovert(pageWidth, pageHeight, doc, userType) {
  const imageFormat = "PNG";
  const chartPositionX = 0.25;
  const chartPositionY = 0.25;
  const chartPosition = {
    positionX: pageWidth * chartPositionX,
    positionY: pageHeight * chartPositionY,
  };
  const chartSize = {
    width: 100,
    height: 70,
  };
  const chartExtravertIntrovert = getElementChart(
    `ExtravertIntrover ${userType}`,
    imageFormat
  );

  doc.addImage(
    chartExtravertIntrovert,
    imageFormat,
    chartPosition.positionX,
    chartPosition.positionY,
    chartSize.width,
    chartSize.height,
    undefined,
    "FAST"
  );
  descriptionExtravertIntrovertPosition(pageWidth, pageHeight, doc);
}

function positionChartSensingIntuition(pageWidth, pageHeight, doc, userType) {
  const imageFormat = "PNG";
  const chartPositionX = 0.25;
  const chartPositionY = 0.3;
  const chartPosition = {
    positionX: pageWidth * chartPositionX,
    positionY: pageHeight * chartPositionY,
  };
  const chartSize = {
    width: 100,
    height: 70,
  };
  const getChartSensingIntuition = getElementChart(
    `SensingIntuition ${userType}`,
    imageFormat
  );
  doc.addImage(
    getChartSensingIntuition,
    imageFormat,
    chartPosition.positionX,
    chartPosition.positionY,
    chartSize.width,
    chartSize.height,
    undefined,
    "FAST"
  );
  descriptionSensingIntuitionPosition(pageWidth, pageHeight, doc);
}
function positionChartThinkingFeeling(pageWidth, pageHeight, doc, userType) {
  doc.addPage();
  const imageFormat = "PNG";
  const chartPositionX = 0.25;
  const chartPositionY = 0.35;
  const chartPosition = {
    positionX: pageWidth * chartPositionX,
    positionY: pageHeight * chartPositionY,
  };
  const chartSize = {
    width: 100,
    height: 70,
  };
  const getChartThinkingFeeling = getElementChart(
    `ThinkingFeeling ${userType}`,
    imageFormat
  );
  doc.addImage(
    getChartThinkingFeeling,
    imageFormat,
    chartPosition.positionX,
    chartPosition.positionY,
    chartSize.width,
    chartSize.height,
    undefined,
    "FAST"
  );
  descriptionThinkingFeelingPosition(pageWidth, pageHeight, doc);
}

function positionChartJudgingPerceiving(pageWidth, pageHeight, doc, userType) {
  const imageFormat = "PNG";
  const chartPositionX = 0.25;
  const chartPositionY = 0.4;
  const chartPosition = {
    positionX: pageWidth * chartPositionX,
    positionY: pageHeight * chartPositionY,
  };
  const chartSize = {
    width: 100,
    height: 70,
  };
  const getChartJudgingPerceiving = getElementChart(
    `JudgingPerceiving ${userType}`,
    imageFormat
  );
  doc.addImage(
    getChartJudgingPerceiving,
    imageFormat,
    chartPosition.positionX,
    chartPosition.positionY,
    chartSize.width,
    chartSize.height,
    undefined,
    "FAST"
  );
  descriptionJudgingPerceivingPosition(pageWidth, pageHeight, doc);
}
function positionChartExtravertSensingThinkingJudging(
  pageWidth,
  pageHeight,
  doc,
  userType
) {
  const imageFormat = "PNG";
  const chartPositionX = 15;
  const chartPositionY = 175;
  const chartSize = {
    width: 90,
    height: 80,
  };
  const chartExtravertSensingThinkingJudging = getElementChart(
    `Extravert-Sensing-Thinking-Judging ${userType}`,
    imageFormat
  );
  doc.addImage(
    chartExtravertSensingThinkingJudging,
    imageFormat,
    chartPositionX,
    chartPositionY,
    chartSize.width,
    chartSize.height,
    undefined,
    "FAST"
  );
}

function positionChartIntrovertIntuitionFeelingPerceiving(
  pageWidth,
  pageHeight,
  doc,
  userType
) {
  const imageFormat = "PNG";
  const chartPositionX = 106;
  const chartPositionY = 175;
  const chartSize = {
    width: 90,
    height: 80,
  };
  const chartIntrovertIntuitionFeelingPerceiving = getElementChart(
    `Introvert-Intuition-Feeling-Perceiving ${userType}`,
    imageFormat
  );
  doc.addImage(
    chartIntrovertIntuitionFeelingPerceiving,
    imageFormat,
    chartPositionX,
    chartPositionY,
    chartSize.width,
    chartSize.height,
    undefined,
    "FAST"
  );
}

export default {
  chartGlobalGenderTypesPersonality,
  chartPersonalityTypeAndJob,
  chartTemperament,
  chartsPersonalities,
  chartsPairOfTypes,
};
