// import Chart from "chart.js/auto";
// import ChartDataLabels from "chartjs-plugin-datalabels";
// import createElementCanvas from "./createElementCanvas.js";
// import store from "../../store";

// const renderGlobalGenderPersonalityTypes = (userType, user, id) => {
//   return new Promise((resolve, reject) => {
//     const gender = user.gender === "male" ? "Mężczyźni" : "Kobiety";
//     const globalGenderTypesPersonality =
//       store.getters["questions/personalityData"];
//     const ctx = createElementCanvas(id, "chart-container-personality-job");
//     ctx.width = 600;
//     ctx.height = 600;
//     const categories = Object.keys(globalGenderTypesPersonality);

//     const Gender = {
//       MALE: "male",
//       FEMALE: "female",
//     };

//     const types = [];
//     const genderCategory = [];

//     categories.forEach((category) => {
//       const categoryData = globalGenderTypesPersonality[category];
//       types.push(...categoryData.map((item) => item.type));

//       const genderValue =
//         user.gender === Gender.MALE
//           ? categoryData.map((item) => item.man)
//           : categoryData.map((item) => item.woman);

//       genderCategory.push(...genderValue);
//     });
//     // const colors = [
//     //   "#E6F7FF",
//     //   "#D9FFE6",
//     //   "#E6E6FF",
//     //   "#FFF2E6",
//     //   "#F2F2F2",
//     //   "#FFFFCC",
//     //   "#FFE6E6",
//     //   "#FFCCCC",
//     //   "#FFD9E6",
//     //   "#F2E6FF",
//     //   "#CCFFCC",
//     //   "#FFECB3",
//     //   "#C9E5FF",
//     //   "#FFB3B3",
//     //   "#E6CCFF",
//     //   "#CCE0FF",
//     // ];
//     const colors = [
//       "#E6F7FF",
//       "#D9FFE6",
//       "#FFFFCC",
//       "#FFE6E6",
//       "#FFD9E6",
//       "#F2E6FF",
//       "#CCFFCC",
//       "#FFECB3",
//       "#C9E5FF",
//       "#FFB3B3",
//       "#E6CCFF",
//       "#CCE0FF",
//       "#FFCC99",
//       "#D9F0D9",
//       "#FFD699",
//       "#FFE6CC",
//     ];
//     new Chart(ctx, {
//       type: "pie",
//       plugins: [ChartDataLabels],
//       data: {
//         labels: types,
//         datasets: [
//           {
//             data: genderCategory,
//             backgroundColor: types.map((type) =>
//               type === userType ? "#FF0000" : colors[types.indexOf(type)]
//             ),
//             borderColor: types.map((type) =>
//               type === userType ? "#FF0000" : "#FFFFFF"
//             ),
//           },
//         ],
//       },
//       options: {
//         devicePixelRatio: 1.5,
//         responsive: false,
//         maintainAspectRatio: false,
//         plugins: {
//           datalabels: {
//             formatter: function (value, context) {
//               const index = context.dataIndex;
//               const label = context.chart.data.labels[index];
//               return label === userType
//                 ? label + " - " + value + "%"
//                 : value + "%" + " - " + label;
//             },
//             // formatter: function (value, context) {
//             //   const index = context.dataIndex;
//             //   const label = context.chart.data.labels[index];
//             //   return label === userType
//             //     ? label + " - " + value + "%"
//             //     : value + "%";
//             // },
//             // formatter: (value) => value + "%",
//             anchor: "end",
//             align: "end",
//             color: "black",
//             labels: {
//               title: {
//                 font: {
//                   weight: "bold",
//                 },
//               },
//             },
//           },
//           legend: {
//             display: false,
//             // position: "bottom",
//             // align: "start",
//             // labels: {
//             //   fontColor: "black",
//             //   usePointStyle: true,
//             //   boxWidth: 10,
//             //   padding: 20,
//             // },
//           },
//           title: {
//             layout: {
//               padding: {
//                 top: 50,
//               },
//             },
//             display: true,
//             text: `Populacja / Typ osobowości - ${gender}`,
//             font: {
//               size: 25,
//             },
//           },
//         },
//         layout: {
//           padding: {
//             left: 80,
//             right: 80,
//             top: 80,
//             bottom: 80,
//           },
//         },
//         animation: {
//           duration: 0,
//           onComplete: function () {
//             resolve();
//           },
//         },
//       },
//     });
//   });
// };

// export default renderGlobalGenderPersonalityTypes;

import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import createElementCanvas from "./createElementCanvas.js";
import store from "../../store";

const renderGlobalGenderPersonalityTypes = (userType, user, id) => {
  return new Promise((resolve, reject) => {
    const gender = user.gender === "male" ? "Mężczyźni" : "Kobiety";
    const globalGenderTypesPersonality =
      store.getters["questions/personalityData"];
    const ctx = createElementCanvas(id, "chart-container-personality-job");
    ctx.width = 600;
    ctx.height = 600;
    const categories = Object.keys(globalGenderTypesPersonality);

    const Gender = {
      MALE: "male",
      FEMALE: "female",
    };

    const types = [];
    const genderCategory = [];

    categories.forEach((category) => {
      const categoryData = globalGenderTypesPersonality[category];
      types.push(...categoryData.map((item) => item.type));

      const genderValue =
        user.gender === Gender.MALE
          ? categoryData.map((item) => item.man)
          : categoryData.map((item) => item.woman);

      genderCategory.push(...genderValue);
    });
    const colors = [
      "#E6F7FF",
      "#D9FFE6",
      "#FFFFCC",
      "#FFE6E6",
      "#FFD9E6",
      "#F2E6FF",
      "#CCFFCC",
      "#FFECB3",
      "#C9E5FF",
      "#FFB3B3",
      "#E6CCFF",
      "#CCE0FF",
      "#FFCC99",
      "#D9F0D9",
      "#FFD699",
      "#FFE6CC",
    ];

    new Chart(ctx, {
      type: "pie",
      plugins: [ChartDataLabels],
      data: {
        labels: types,
        datasets: [
          {
            data: genderCategory,
            backgroundColor: types.map((type) =>
              type === userType ? "#FF0000" : colors[types.indexOf(type)]
            ),
            borderColor: types.map((type) =>
              type === userType ? "#FF0000" : "#FFFFFF"
            ),
          },
        ],
      },
      options: {
        devicePixelRatio: 1.5,
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            formatter: function (value, context) {
              const index = context.dataIndex;
              const label = context.chart.data.labels[index];
              return label + " \n " + value + "%";
            },
            offset: 5,
            anchor: "end",
            align: "end",
            color: "black",
            labels: {
              title: {
                // font: {
                //   weight: "bold",
                // },
              },
            },
          },
          legend: {
            display: false,
            position: "bottom",
          },
          title: {
            padding: {
              top: 20,
              bottom: 50,
            },
            display: true,
            text: `Populacja / Typ osobowości - ${gender}`,
            font: {
              size: 20,
            },
          },
        },
        layout: {
          padding: {
            left: 60,
            right: 60,
            top: 60,
            bottom: 60,
          },
        },
        animation: {
          duration: 0,
          onComplete: function () {
            resolve();
          },
        },
      },
    });
  });
};

export default renderGlobalGenderPersonalityTypes;
