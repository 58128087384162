import getUserTemperament from "./temperamenty/descTemperament";

const generateUserSummary = (
  doc,
  pageWidth,
  fontSizeNormal,
  fontSizeBold,
  fontSizeTitle,
  user,
  userType,
  userTypePersonality,
  setFontNormal,
  setFontBold
) => {
  displayFullNameInHeader(doc, user, fontSizeBold, pageWidth, setFontBold);
  displayUserTemperamentDescription(
    doc,
    userType,
    fontSizeNormal,
    setFontNormal
  );
};

export default generateUserSummary;

function displayFullNameInHeader(
  doc,
  user,
  fontSizeBold,
  pageWidth,
  setFontBold
) {
  const fullName = `${user.firstName} ${user.lastName[0] + "."}`;
  doc.setFontSize(fontSizeBold);
  setFontBold();
  const nameMarginRight = 15;
  const nameWidth = doc.getTextWidth(fullName);
  const namePositionX = pageWidth - nameWidth - nameMarginRight;
  const namePositionY = 20;
  doc.text(fullName, namePositionX, namePositionY);
}

function displayUserTemperamentDescription(
  doc,
  userType,
  fontSizeNormal,
  setFontNormal
) {
  doc.setFontSize(fontSizeNormal);
  setFontNormal();
  const descUserTemperamentPositionX = 15;
  const descUserTemperamentPositionY = 30;
  const userTemperament = getUserTemperament(userType);
  const splitDescUserTemperament = doc.splitTextToSize(userTemperament, 185);
  doc.text(
    splitDescUserTemperament,
    descUserTemperamentPositionX,
    descUserTemperamentPositionY
  );
}
