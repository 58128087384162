import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import createElementCanvas from "./createElementCanvas.js";
import store from "../../store";

const renderPersonalityTypeAndJob = (id, userType) => {
  return new Promise((resolve, reject) => {
    const personalityTypeAndJob = store.getters["questions/personalityData"];
    const ctx = createElementCanvas(id, "chart-container-personality-job");
    const SJData = getPersonalityTypeData(personalityTypeAndJob, "SJ");
    const SPData = getPersonalityTypeData(personalityTypeAndJob, "SP");
    const NTData = getPersonalityTypeData(personalityTypeAndJob, "NT");
    const NFData = getPersonalityTypeData(personalityTypeAndJob, "NF");
    new Chart(ctx, {
      type: "bar",
      plugins: [ChartDataLabels],
      data: {
        datasets: [
          {
            label: "SJ Obrończyni",
            data: SJData,
            backgroundColor: ["rgb(32,178,170)"],
            borderWidth: 1,
          },
          {
            label: "SP Twórcy",
            data: SPData,
            backgroundColor: ["rgb(255,228,181)"],
            borderWidth: 1,
          },
          {
            label: "NT Intelektualiści",
            data: NTData,
            backgroundColor: ["rgb(34,139,34)"],
            borderWidth: 1,
          },
          {
            label: "NF Wizjonerzy",
            data: NFData,
            backgroundColor: ["rgb(255,165,0)"],
            borderWidth: 1,
          },
        ],
      },
      options: {
        devicePixelRatio: 1.5,
        parsing: {
          xAxisKey: "type",
          yAxisKey: "value",
        },
        aspectRatio: 0,
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: `Populacja / Typ osobowosci ${userType}`,
            font: {
              size: 25,
            },
          },
          legend: {
            display: true,
          },
          datalabels: {
            formatter: (value, context) => {
              return value.value + "%";
            },
            anchor: "end",
            align: "top",
            offset: 2,
            labels: {
              value: {
                color: "black",
              },
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            title: {
              display: true,
              text: "Typ osobowości",
            },
          },
          y: {
            stacked: true,
            min: 0,
            max: 20,
            beginAtZero: true,
            title: {
              display: true,
              text: "Populacja",
            },
            ticks: {
              callback: (value, index, values) => {
                return value + "%";
              },
            },
          },
        },
        animation: {
          duration: 0,
          onComplete: function () {
            resolve();
          },
        },
      },
    });
  });
};
function getPersonalityTypeData(personalityTypeAndJob, category) {
  return personalityTypeAndJob[category].map((item) => ({
    type: `${item.type} ${item.job}`,
    value: item.value,
  }));
}
export default renderPersonalityTypeAndJob;
