import logoFooter from "../../assets/logo/logo.png";
import jsPDF from "jspdf";

export function footer(doc) {
  const logo = displayFooterLogo(doc);
  addPageNumbers(doc, logo);
}

function addPageNumbers(doc, logo) {
  const pageCount = doc.internal.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.addImage(
      logo.imageData,
      "PNG",
      logo.positionX,
      logo.positionY,
      logo.width,
      logo.height
    );
    doc.text(185, 290, `Strona ${i}`);
  }
}

function displayFooterLogo(doc) {
  const logoWidth = 35;
  const logoHeight = 15;
  const logoOptions = {
    imageData: logoFooter,
    positionX: doc.internal.pageSize.width / 2 - logoWidth / 2,
    positionY: 280,
    width: logoWidth,
    height: logoHeight,
  };
  return logoOptions;
}
