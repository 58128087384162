import autoTable from "jspdf-autotable";

const descPairsTypes = (
  doc,
  list1,
  list2,
  columnNames1,
  columnNames2,
  positionX,
  positionY
) => {
  const dataToRender = [];
  for (let i = 0; i < Math.max(list1.length, list2.length); i++) {
    const row = [];
    row.push(list1[i] || "");
    row.push(list2[i] || "");
    dataToRender.push(row);
  }
  const headers = [[`${columnNames1} częściej`, `${columnNames2} częściej`]];

  const headStyles = {
    fillColor: "#CCCCCC",
    textColor: "#000000",
    fontStyle: "bold",
  };
  const contentStyle = {
    fillColor: "#FFFFFF",
    textColor: "#000000",
  };

  const options = {
    startY: positionY,
    startX: positionX,
    styles: {
      font: "Montserrat",
      halign: "center",
      fontSize: 10,
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      valign: "middle",
      align: "left",
      fillColor: [255, 255, 255],
    },
    columnStyles: {
      0: { cellWidth: 90 },
      1: { cellWidth: 90 },
    },
  };

  doc.autoTable({
    head: headers,
    body: dataToRender,
    theme: "grid",
    headStyles: headStyles,
    styles: contentStyle,
    ...options,
  });

  return doc;
};

export default descPairsTypes;
